//new
import getToken from 'utils/auth/get-token';
import { API_BUG_REPORT } from 'constants/api-urls';
import axios from 'axios';

export const sendBugReportEffect = async (form: any) =>
  axios.post(`${API_BUG_REPORT}`, form, {
    headers: {
      'x-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
