/* eslint-disable no-debugger */
import { generateRandomString } from 'utils/string'

const createTxtFile = (text, name) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const file = new File([text], `${name}.txt`, {
    lastModified: Date.now(),
    type: 'text/plain',
  });

  return { url, file };
}

const createMemoFile = (text, name) => {
  const tempFile = {
    time: new Date().getTime(),
    blocks: text.split('\n')
      .map((text) => ({
      id: generateRandomString(10),
        type: "paragraph",
      data: {
            text
        }
    })),
    version: '2.26.0',
    name: name,
  }

  const blob = new Blob([JSON.stringify(tempFile)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const file = new File([JSON.stringify(tempFile)], `${name}.memo`, {
    lastModified: Date.now(),
    type: 'application/json',
  });

  return { url, file };
}

const createPageFile = ({ name, extension, folder = '' }) => {
  const tempFile = {
    name,
    folder,
  };
  const blob = new Blob([JSON.stringify(tempFile)], {
    type: 'application/json',
  });
  const fileName = `${name}.${extension}`;
  const file = new File([blob], fileName, { type: 'application/json' });
  return file;
};

export { createTxtFile, createMemoFile, createPageFile };
