// Refactoring №3
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import MainContext from 'store/main/context/main-context';
import {
  tokenUtilization,
  upscaleImageEffect,
} from 'store/aiGenerator/effects';
import * as AiGeneratorActions from 'store/home/actions/file/ai-generator.action';
import { uploadMultiFileEffectRedux } from 'store/home/effects';
import { getPreviewFileBlob } from 'store/home/effects/file/get-preview-file-blob';
import TokenCostContext from 'store/tokenCost/contexts';
import { extractTokensAction } from 'features/app';

import useNotification from 'utils/hooks/use-notification';
import { TOKEN_COST } from 'config/token-cost';

import SpinnerLoader from '../ImageToImageModal/SpinnerLoader';
import { AiGeneratorsErrorModal } from '../ImageToImageModal/AiGeneratorsErrorModal';
import Button, { ButtonTheme } from 'components/Button';
import Preloader from 'components/shared/Preloader';
import SelectSizeButtons from './SelectSizeButtons';

import { ReactComponent as Corner } from 'static/assets/svg/ai-generator-corner.svg';
import { ReactComponent as ObtuseCorner } from 'static/assets/svg/ai-generator-obtuse-corner.svg';
import CloseIcon from 'components/svg/close';

import style from './style.module.scss';

type BugReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imageAsObj: imageAsObj;
};
export type imageAsObj = {
  mime: string;
  name: string;
  slug: string;
  folderId: string;
  size: number;
};
const aiGetGenerationPhase = (isLoading: boolean, isGeneratedImage: string) => {
  let prompt = 'READY FOR A GENERATION';
  if (isLoading) prompt = ' CREATING...';
  if (isGeneratedImage) prompt = ' GENERATION COMPLETE.';
  return prompt;
};

export default function AiUpScallerModal({
  isOpen,
  imageAsObj,
}: BugReportModalProps) {
  const { t } = useTranslation('contextMenu');
  const { mime, name, slug, folderId, size } = imageAsObj;
  const [firstImageBlob, setFirstImageBlob] = useState({
    imageView: '',
    blob: null,
  });
  const [selectedUpscaleSize, setSelectedUpscaleSize] = useState({
    value: 0,
    size: 0,
  });
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isError, setError] = useState({ isError: false, message: '' });

  const folderIdForUpload = folderId === 'main' ? null : folderId;
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const {
    state: {
      workspace: { storage },
    },
  } = useContext(MainContext);
  const { tokenCost } = useContext(TokenCostContext);
  const upscaledImageCost = tokenCost.reduce(
    (_, item: { type: number; tokens: number }) => {
      if (TOKEN_COST.AIImageUpscale === item.type) {
        return item.tokens;
      }
      return _;
    },
    0
  );

  const onClose = () => {
    dispatch(AiGeneratorActions.endUpscale());
  };

  function resizeImage(blob: Blob, callback: BlobCallback) {
    const resizedSizes = 512;
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = resizedSizes;
      canvas.height = resizedSizes;
      if (ctx) ctx.drawImage(img, 0, 0, resizedSizes, resizedSizes);
      canvas.toBlob(callback, 'image/jpeg', 0.8);
    };
  }

  const cancelHandler = useCallback(() => {
    if (isError.isError) {
      setError({ isError: false, message: '' });
      return;
    }
    onClose();
    setFirstImageBlob({
      imageView: '',
      blob: null,
    });
    setGeneratedImage(null);
    setLoading(false);
    setFileToUpload(null);
  }, [firstImageBlob, generatedImage, isLoading, fileToUpload, isError]);

  useEffect(() => {
    setLoading(true);
    getPreviewFileBlob({ file: imageAsObj, sendStatistic: false }).then(
      (data) => {
        if (data) {
          resizeImage(data, (resizedImage) => {
            if (resizedImage !== null) {
              const viewImage = URL.createObjectURL(resizedImage);
              setFirstImageBlob({ blob: resizedImage, imageView: viewImage });
              setLoading(false);
            }
          });
        }
      }
    );
  }, []);

  function base64ToBlob(base64String: string, contentType: string) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const generateImageHandler = async () => {
    setLoading(true);
    const imageAsFile = new File([firstImageBlob.blob], name, {
      type: mime,
    });
    const form = new FormData();
    form.append('image', imageAsFile);
    form.append('width', selectedUpscaleSize.size);
    upscaleImageEffect(form)
      .then((data) => {
        tokenUtilization(3).then(() => {
          dispatch(extractTokensAction(upscaledImageCost));
        });
        setGeneratedImage(data.imageUrl);
        const blob = base64ToBlob(data.base64Image, 'image/png');
        const imageAsFile = new File(
          [blob],
          `upscaled_to_${selectedUpscaleSize.size}px_image.jpg`,
          {
            type: 'image/jpeg',
            lastModified: new Date() as any,
          }
        );
        // @ts-ignore:next-line
        imageAsFile.folderId = folderIdForUpload;
        setFileToUpload(imageAsFile);
      })
      .catch(() =>
        setError({
          isError: true,
          message: t('main.upscale.resolutionError'),
        })
      )
      .finally(() => setLoading(false));
  };
  const saveImageHandler = () => {
    dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files: [fileToUpload],
        folderId: folderIdForUpload,
        storageType: storage,
        addNotification: addNotification(
          t('main.upscale.artWorkSaved'),
          'success'
        ),
        setErrors: false,
        isGeneratedByAi: true,
      })
    );
  };

  const handleGenerationStage = () => {
    const text = aiGetGenerationPhase(isLoading, generatedImage);
    switch (text) {
      case 'READY FOR A GENERATION':
        return t('main.imageToImage.readyForGen');
      case ' CREATING...':
        return t('main.imageToImage.creatingAIModal');
      case ' GENERATION COMPLETE.':
        return t('main.imageToImage.completeGenAIModal');
      default:
        return t('main.imageToImage.readyForGen');
    }
  };
  return (
    <>
      {firstImageBlob || generatedImage ? (
        <Modal
          isOpen={isOpen}
          overlayClassName={style.overlay}
          className={style.modal}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick
          onRequestClose={cancelHandler}
        >
          <div className={style.modal__wrapper}>
            <button
              type="button"
              className={style.modal__exit}
              onClick={cancelHandler}
            >
              <CloseIcon />
            </button>
            <div className={style.modal__upscalerContainer}>
              <h2 className={style.modal__title}>
                <span className={style.modal__title__icon}></span>
                <label className={style.modal__title__icon__label}>ai </label>
                {t('main.upscale.upscale')}
              </h2>
              <div className={style.modal__imageContent}>
                <ObtuseCorner />
                <Corner />
                <Corner />
                <Corner />
                <span className={style.modal__imageContent__textLoader}>
                  <label className={style.modal__title__icon__label}>ai</label>
                  {handleGenerationStage()}
                </span>
                {!isLoading ? (
                  <img
                    className={style.modal__imageContent__image}
                    src={generatedImage || firstImageBlob.imageView}
                    alt={''}
                  />
                ) : (
                  <SpinnerLoader />
                )}
                <div className={style.modal__imageContent__bottomInformation}>
                  <span
                    className={
                      style.modal__imageContent__bottomInformation__credits
                    }
                  >
                    {t('main.upscale.credits')}
                    <div /> 6
                  </span>
                  <span
                    className={
                      style.modal__imageContent__bottomInformation__size
                    }
                  >
                    {selectedUpscaleSize.size > 0 &&
                      `${selectedUpscaleSize.size}px`}
                  </span>
                </div>
              </div>
              <div className={style.modal__selectSizeButtonsWrapper}>
                <SelectSizeButtons setSelectedSize={setSelectedUpscaleSize} />
              </div>
              <div className={style.modal__buttons}>
                <Button
                  className={`${style.modal__button} ${
                    (isLoading || !selectedUpscaleSize.value) &&
                    style.modal__button__disabled
                  }`}
                  theme={ButtonTheme.NEW_BORDERRED_DARK}
                  onClick={generateImageHandler}
                  disabled={isLoading || !selectedUpscaleSize.value}
                  showPreloader={isLoading}
                >
                  {t('main.upscale.upscaleArt')}
                </Button>
                <Button
                  className={`${style.modal__button} ${
                    (isLoading || !generatedImage) &&
                    style.modal__button__disabled
                  }`}
                  theme={ButtonTheme.NEW_BORDERRED_DARK}
                  onClick={saveImageHandler}
                  disabled={isLoading || !generatedImage}
                  showPreloader={false}
                >
                  {t('main.upscale.save')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Preloader />
      )}
      <AiGeneratorsErrorModal
        isOpen={isError.isError}
        error={isError.message}
        onClose={cancelHandler}
      />
    </>
  );
}
