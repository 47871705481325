import axios from 'axios';
import getToken, { getRefreshToken } from 'utils/auth/get-token';
import { setErrors } from 'store/errors/actions/index';
// import { authTokenRefresh } from 'store/auth/effects';
import removeToken from 'utils/auth/remove-token';

import { store } from 'store/root-store';
import { NEIRA_AI_API } from 'constants/api-urls';
// import setToken from 'utils/auth/set-token';
import { setToken } from 'web3-auth/lib/es5';
import { getUserIp } from 'utils/getUserIp';

const base = (method: string, url: string, data?: any, other?: any) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const { headers = {}, options = {} } = other || {};
    const token = getToken();
    const requestConfig = {
      method,
      data,
      url,
      headers: {
        Authorization: getToken(),
        Accept: 'application/json, text/plain, */*',
        ...headers,
      },
      ...options,
    };
    if (!token) delete requestConfig.headers['X-Token'];

    const userIp = await getUserIp();
    if (userIp) {
      requestConfig.headers['X-Real-IP'] = userIp;
    }
    try {
      const response = await axios(requestConfig);
      resolve(response);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        try {
          const refresh_token = getRefreshToken();
          const data = await axios({
            method: 'post',
            url: `${NEIRA_AI_API}/auth/refresh_token`,
            headers: {
              'Content-Type': 'application/json',
              'X-Real-IP': userIp,
            },
            data: { refresh_token },
          });
          setToken(data?.data);
          try {
            requestConfig.headers = {
              Authorization: getToken(),
              Accept: 'application/json, text/plain, */*',
              'X-Real-IP': userIp,
              ...headers,
            };
            const response = await axios(requestConfig);
            resolve(response);
          } catch (error) {
            reject(error);
          }
        } catch (error) {
          removeToken();
          window.location.href = '/connect';
        }
      }
      if (error?.response?.status === 403 || error?.response?.status === 412) {
        const me = store.getState().account.userInfo.entities;
        if (
          !(
            me.is_password_access &&
            me.password_access &&
            !me.password_access?.enabled
          )
        ) {
          store.dispatch(
            setErrors(error?.response?.data, error?.response?.status)
          );
        }
      }
      //TODO TMP START
      try {
        const refresh_token = getRefreshToken();
        const data = await axios({
          method: 'post',
          url: `${NEIRA_AI_API}/auth/refresh_token`,
          headers: {
            'Content-Type': 'application/json',
            'X-Real-IP': userIp,
          },
          data: { refresh_token },
        });
        setToken(data?.data);
        try {
          requestConfig.headers = {
            Authorization: getToken(),
            Accept: 'application/json, text/plain, */*',
            'X-Real-IP': userIp,
            ...headers,
          };
          const response = await axios(requestConfig);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      } catch (error) {
        removeToken();
        window.location.href = '/connect';
      }
      //TODO TMP END

      reject(error);
    }
  });

const authRequest: {[key: string]: (url: string, data?: any, other?: any) => Promise<unknown> } = {};
['get', 'post', 'put', 'delete', 'patch'].forEach((method: string) => {
  authRequest[method] = (url: string, data?: any, other?: any) => base(method.toUpperCase(), url, data, other);
});
export default authRequest;
