import i18next from 'i18next';
import axios from 'axios';
import { pki, util } from 'node-forge';
import { encodeExistingFile } from 'gdgateway-client/lib/es5';

import getImagePreviewEffect from 'store/home/effects/file/get-image-preview.effect';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { getOneTimeToken } from 'store/home/effects';

import { encodeFileData } from 'utils/file/library-callbacks';
import { convertArrayBufferToBase64 } from 'utils/file/convertArrayBufferToBase64';
import { setExistingThumbnail } from 'utils/file/getThumbnail';
import { setKeyToLS } from 'utils/file/setKeyToLS';
import { saveEncryptedFileKeys } from 'utils/crypto/saveEncryptedFileKeys';
import { getKeysByWorkspace } from 'utils/crypto/getKeysByWorkspace';
import { canUserEncryptFile } from 'utils/canUserEncryptFile.js';

export const encryptAction = async ({
  user,
  userTokens,
  file,
  dispatch,
  addNotification,
  getEncryptionStatus,
  afterCb,
}) => {
  if (!userTokens) {
    addNotification(
      i18next.t('contextMenu:main.imageToImage.lowBalanceText'),
      'alert'
    );
    return;
  }

  const canEncrypt = await canUserEncryptFile(user?.user_public_addresses);
  if (!canEncrypt.error) {
    const { handlers, callbacks } = encodeFileData;

    const callback = ({ type, params }) => {
      if (handlers.includes(type)) {
        callbacks[type]({ ...params, dispatch });
      } else {
        console.error(`Handler "${type}" isn't provided`);
      }
    };

    const key = await crypto.subtle.generateKey(
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    const {
      data: { keys },
    } = await getKeysByWorkspace();

    const fileSignal = axios.CancelToken.source();
    const hasThumbnail =
      file.mime.startsWith('image') || file.mime.startsWith('video');
    let thumbnail;

    if (hasThumbnail) {
      thumbnail = await getImagePreviewEffect(
        file.slug,
        300,
        164,
        'crop',
        fileSignal.token,
        file.mime
      );
    }
    const {
      data: {
        jwt_ott: downloadJwtOTT,
        user_tokens: { token: downloadToken },
        gateway: downloadEndpoint,
      },
    } = await getDownloadOTT([{ slug: file.slug }]);

    const {
      data: { jwt_ott, user_token, gateway },
    } = await getOneTimeToken([
      {
        filename: file.name,
        filesize: file.size,
        isPublic: true,
      },
    ]);
    const oneTimeToken = user_token[0].token;

    const result = await encodeExistingFile({
      file,
      getImagePreviewEffect,
      oneTimeToken,
      downloadJwtOTT,
      jwtOneTimeToken: jwt_ott[0],
      gateway,
      downloadToken,
      downloadEndpoint: downloadEndpoint.url,
      callback,
      handlers,
      key,
    });
    const slug = result?.data?.slug ?? file.slug;
    const bufferKey = await crypto.subtle.exportKey('raw', key);
    const base64Key = convertArrayBufferToBase64(bufferKey);

    setKeyToLS({ slug, base64Key });

    let encryptedKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const publicKey = pki.publicKeyFromPem(keys[i]);
      const encryptedKey = await publicKey.encrypt(base64Key);
      const encryptedHexKey = util.bytesToHex(encryptedKey);
      encryptedKeys = [
        ...encryptedKeys,
        { publicKey: keys[i], encryptedFileKey: encryptedHexKey },
      ];
    }

    afterCb && afterCb();

    saveEncryptedFileKeys({
      slug,
      encryptedKeys: encryptedKeys,
    });

    if (hasThumbnail) {
      setExistingThumbnail({ file: result.data, thumbnail });
    }

    getEncryptionStatus && getEncryptionStatus('success');
  } else {
    addNotification(canEncrypt?.message, 'alert');
  }
  return;
};
