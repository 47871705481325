import { getFromCookies } from 'utils/cookies';

const getRefreshToken = () => getFromCookies('refresh_token');

export { getRefreshToken };

export default function () {
  const token = getFromCookies('access_token');
  return token ? `Bearer ${token}` : false;
}
