// //Refactoring №4
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CN from 'classnames';

import * as actions from 'store/home/actions/file/download-file.action';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';

import CustomFileIcon from 'components/CustomFileIcon/CustomFileIcon';
import DownloadIcon from 'components/svg/download-header';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import actionsOptions from 'config/actions-options';
import { getPublicSharedFileContent } from 'store/home/effects/file/get-file-content';
import getToken from 'utils/auth/get-token';
import downloadFromBlob from 'utils/download-from-blob';

import { IFile } from 'interfaces/file-folder';

interface DefaultContentProps {
  file: IFile;
  text: string;
  fileContentRef: string;
}

const DefaultContent: FC<DefaultContentProps> = ({
  file,
  fileContentRef,
  text = '',
}) => {
  const { t } = useTranslation('contextMenu');
  const { t: ownerT } = useTranslation('owner');
  const dispatch = useDispatch();
  const userToken = getToken();
  const isAuthorized = !!userToken;
  const canDownload = file?.is_downloaded;

  const color = useMemo(() => {
    return file.color?.length > 0
      ? file.color[file.color.length - 1].hex
      : '#FFFFFF';
  }, [file.color]);

  const downloadFile = useCallback(() => {
    if (canDownload) {
      dispatch(
        actions.startDownload(getTotalDownloadFileSize([file]), file.slug, [file])
      );
      if (isAuthorized) {
        dispatch(fileFolderActionHandlerEffect(file, actionsOptions.download));
      } else {
        const fileSignal = axios.CancelToken.source();
        getPublicSharedFileContent(
          file.slug,
          fileSignal.token,
          true,
          file.gateway
        ).then((data) => {
          dispatch(actions.endDownload());
          downloadFromBlob(data, file.name);
        });
      }
    }
  }, [file]);

  return (
    <div className="file-view__image-container">
      <div
        className="file-content-image-container-default"
        ref={fileContentRef}
      >
        <CustomFileIcon
          color={color}
          extension={file?.extension}
          dateCreated={file?.created_at}
        />
        <div className="file-content-image-title">
          {text || ownerT('files.noPreview')}
        </div>
        <button
          onClick={downloadFile}
          className={CN(
            'button-raw file-view__button',
            !canDownload && 'file-view__button_disabled'
          )}
        >
          <DownloadIcon color="#FFFFFF" />
          <span>{t('files.download.download')}</span>
        </button>
      </div>
    </div>
  );
};

export default DefaultContent;
