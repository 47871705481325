// libs
import axios from 'axios';

// utils
import getToken from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';
import { API_PAYMENT, API_WORKSPACE, API_TOKENS, API_USER } from 'constants/api-urls';

// config
import { paymentTypes } from 'config/payment-types';

// store
import * as actions from '../../actions/index';

export function getStripeDataEffectWithCallback(amount, successCb) {
  return async (dispatch) => {
    dispatch(actions.getStripeData());

    const url = `${API_PAYMENT}/stripe/prepare`;

    const inst = axios.create({
      headers: {
        'X-Token': getToken(),
      },
    });

    try {
      const result = await inst.get(url, {
        params: {
          totalAmount: amount,
        },
      });
      dispatch(actions.getStripeDataSuccess(result));
      successCb && successCb();
    } catch {
      dispatch(actions.getStripeDataFailure());
    }
  };
}

export const prepareStripeDataEffect = async (amount, params = {}) =>
  axios
    .create({
      headers: {
        'X-Token': getToken(),
      },
    })
    .get(`${API_PAYMENT}/${paymentTypes.stripe.type}/prepare`, {
      params: {
        totalAmount: amount,
        ...params,
      },
    })
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const prepareStorageStripeDataEffect = async ({ price, storage }) =>
  axios
    .create({
      headers: {
        'X-Token': getToken(),
      },
    })
    .get(
      `${process.env.REACT_APP_API_PATH}/storage/${paymentTypes.stripe.type}/prepare`,
      {
        params: {
          price,
          storage,
        },
      }
    )
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const prepareUpdateStorageStripeEffect = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/update/storage`, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const prepareCreditStripeDataEffect = async ({ price, credits }) =>
  axios
    .create({
      headers: {
        'X-Token': getToken(),
      },
    })
    .get(
      `${process.env.REACT_APP_API_PATH}/credits/${paymentTypes.stripe.type}/prepare`,
      {
        params: {
          price,
          credits,
        },
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((response) => {
      throw response;
    });

export const prepareWorkspaceStripeDataEffect = async (params) =>
  axios
    .create({
      headers: {
        'X-Token': getToken(),
      },
    })
    .get(`${API_WORKSPACE}/storage/${paymentTypes.stripe.type}/prepare`, {
      params,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((response) => {
      throw response;
    });


export const createStripeSorageSub = async (priceId, quantity = 1) => {
  try {
    const { data } = await authRequest.post(
      `${API_WORKSPACE}/storage/create/subscription`,
      [{price: priceId, quantity}]
    )
  
    return { ...data, priceId };
  } catch (error) {
    console.error(error)
  }
};

export const createStripeTokensBuy = async (price, tokens) => {
  try {
    const { data } = await authRequest.get(
      `${API_TOKENS}/stripe/prepare`,
      null,
      { options: { params: { price, tokens }}}
    )
  
    return { ...data };
  } catch (error) {
    console.error(error)
  }
};
  
export const checkPayment = async (payId) => {
  try {
    const { data } = await authRequest.post(
      `${API_WORKSPACE}/storage/check/payment`,
      { payment: payId }
      )
  
    return data.success;    
  } catch (error) {
    console.error(error)
  }
};

export const updateWsStorage = async (price, subscription) => {
  try {
    const url = `${API_WORKSPACE}/update/storage`;
    const body = {price, stripe_sub_id: subscription};
    const data = await authRequest.post(url, body)
    return data;
  } catch (error) {
    console.error(error)
  }
};

export const updateUserTokens = async (payment, tokens, price) => {
  try {
    const url = `${API_USER}/tokens`;
    const body = { payment, tokens, price };
    const data = await authRequest.post(url, body)
    return data;
  } catch (error) {
    console.error(error)
  }
};
