/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
//Refactoring №3
import axios from 'axios';
import * as R from 'ramda';
import useAxios from 'axios-hooks';

import {
  API_FOLDERS,
  API_GET_FILE,
  API_FILES_SEARCH,
  API_FILE,
  API_PUB,
  API_VAULT,
} from 'constants/api-urls';

import { getFilesUpdateFavorite } from 'store/home/actions/files/get-files.actions';
import { updateSorter, SORT_DIR, SORT_PAGE_TYPE } from 'features/app';

import { createSecurityReqObj } from 'utils/request/create-security-req-obj';
import authRequest from 'utils/request/auth-request';
// import getToken from 'utils/auth/get-token';

import * as actions from '../../actions/files/get-files.actions';
import * as documentActions from '../../actions/documents.actions';
import * as vaultActions from '../../actions/vaults.actions';
import * as linkActions from '../../actions/links.actions';
import * as uploadActions from '../../actions/upload-file.actions';

export const getFilteredFilesEffect =
  (fileType, userParams = {}) =>
  async () => {
    const url = `${API_GET_FILE}?${
      fileType === 'isAiGenerated'
        ? 'isAiGenerated=true'
        : `extension=${fileType}`
    }`;

    const result = await authRequest.get(url, null, {
      options: {
        params: {
          ...userParams,
        },
      },
    });

    return result.data;
  };

/**
 *
 * @param {number} folderId
 * @param {object} userParams
 * @param {boolean} [clearBeforeGet=false] - define if we need to clear files storage before request new files
 * @param {string} [cancelToken=''] - abort request
 * @param {boolean} [tokenized=false] - abort request
 * @param {boolean} [initialCommit=false] - abort request
 * @returns {Function}
 */
export const getFilesDispatchEffect =
  (
    folderId = null,
    userParams = {},
    clearBeforeGet = false,
    cancelToken,
    tokenized,
    initialCommit
  ) =>
  async (dispatch, getState) => {
    if (clearBeforeGet) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }

    const state = getState();
    const searchData = R.path(['home', 'search', 'searchData'], state);
    const securities = R.path(['home', 'getFiles', 'securities'], state);
    if (userParams.page === 1 && userParams.order_by) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }
    dispatch(actions.getFiles());

    const url = tokenized
      ? `${API_GET_FILE}/tokenized${folderId ? `/` + folderId : ''}`
      : R.isEmpty(searchData)
      ? `${API_GET_FILE}${folderId ? `/` + folderId : ''}`
      : API_FILES_SEARCH;

    await authRequest
      .get(url, null, {
        options: {
          cancelToken,
          params: {
            ...userParams,
            ...searchData,
            ...createSecurityReqObj(securities),
          },
        },
      })
      .then((result) => {
        const files = {
          ...result,
          data: {
            ...result?.data,
            data: result?.data?.data?.map((file) => ({
              ...file,
              folderId: folderId ?? 'main',
            })),
          },
        };

        if (initialCommit) {
          dispatch(
            updateSorter({
              initialCommit: true,
              option: result.data.orderBy || 'createdAt',
              dir: result.data.orderDirection || SORT_DIR.asc,
              page: folderId ? SORT_PAGE_TYPE.folder : SORT_PAGE_TYPE.main,
            })
          );
        }
        dispatch(actions.getFilesSuccess(files));
      });
  };

export const getFilesEffect = async (
  folderId = null,
  userParams = {},
  cancelToken
) => {
  const url = `${API_GET_FILE}${folderId ? `/` + folderId : ''}`;

  return authRequest
    .get(url, null, { options: { cancelToken, params: { ...userParams } } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getPublicSharedFilesEffect = async (
  folderId = null,
  userParams = {},
  page,
  cancelToken
) => {
  const url = `${API_PUB}/entries/${folderId}${page ? `?page=${page}` : ''}`;

  return axios
    .get(url, {
      params: {
        ...userParams,
      },
      cancelToken,
    })
    .then((response) => {
      let data = response.data;
      data.folderId = folderId;
      return data;
    })
    .catch((e) => {
      throw e;
    });
};

export const useGetPublicSharedFilesEffect = (
  folderId = null,
  userParams = {},
  page,
  cancelToken
) => {
  const url = `${API_PUB}/entries/${folderId}${page ? `?page=${page}` : ''}`;
  const [{ data, loading, error }, refetch] = useAxios({
    url,
    params: {
      ...userParams,
    },
    cancelToken,
  });

  return { data: { ...data, folderId }, loading, error, refetch };
};
export const getDocumentsEffect =
  (userParams = {}, cancelToken) =>
  async (dispatch) => {
    const url = `${API_GET_FILE}`;

    await authRequest
      .get(url, null, {
        options: {
          cancelToken,
          params: {
            ...userParams,
            order_by: 'created',
            order: 'asc',
            extension: 'docs',
          },
        },
      })
      .then((response) => {
        dispatch(
          documentActions.documentsGetSuccess({
            ...response.data,
            page: userParams.page,
          })
        );
      })
      .catch((e) => {
        dispatch(documentActions.documentsGetFailure(e));
      });
  };
export const getVaultsEffect =
  (userParams = {}, cancelToken) =>
  async (dispatch) => {
    const url = `${API_VAULT}`;
    await authRequest
      .get(url, null, {
        options: {
          cancelToken,
          params: {
            ...userParams,
          },
        },
      })
      .then((response) => {
        dispatch(
          vaultActions.vaultsGetSuccess({
            ...response.data,
            page: userParams.page,
          })
        );
      })
      .catch((e) => {
        dispatch(vaultActions.vaultsGetFailure(e));
      });
  };

export const getLinksEffect =
  (userParams = {}, cancelToken) =>
  async (dispatch) => {
    const url = `${API_GET_FILE}`;
    await authRequest
      .get(url, null, {
        options: {
          cancelToken,
          params: {
            ...userParams,
            extension: 'link',
          },
        },
      })
      .then((response) => {
        dispatch(
          linkActions.linksGetSuccess({
            ...response.data,
            page: userParams.page,
          })
        );
      })
      .catch((e) => {
        dispatch(vaultActions.vaultsGetFailure(e));
      });
  };

/**
 *
 * @param {object} userParams
 * @param {boolean} [clearBeforeGet=false] - define if we need to clear files storage before request new files
 * @param {string} [modifier=''] - get deleted/recent files
 * @param {string} [cancelToken=''] - abort request
 * @returns {Function}
 */
export const getSharedFilesEffect = (
  userParams = {},
  clearBeforeGet = false,
  modifier = 'my',
  cancelToken,
  initialCommit
) => {
  return async (dispatch, getState) => {
    if (clearBeforeGet) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }

    const state = getState();
    const securities = R.path(['home', 'getFiles', 'securities'], state);
    if (userParams.page === 1 && userParams.order_by) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }
    dispatch(actions.getFiles());

    const url = `${API_GET_FILE}/shared${modifier ? '/' + modifier : ''}`;

    await authRequest
      .get(url, null, {
        options: {
          cancelToken,
          params: {
            ...userParams,
            ...createSecurityReqObj(securities),
          },
        },
      })
      .then((result) => {
        const files = {
          ...result,
          data: {
            ...result?.data,
            data: result.data.data.map((file) => ({
              ...file,
              folderId: 'sharing',
            })),
          },
        };

        if (initialCommit) {
          dispatch(
            updateSorter({
              initialCommit: true,
              option: result.data.orderBy || 'createdAt',
              dir: result.data.orderDirection || SORT_DIR.asc,
              page: SORT_PAGE_TYPE.shared,
            })
          );
        }

        dispatch(actions.getFilesSuccess(files, userParams.page === 1));
      })
      .catch((e) => {
        console.warn(e);
      });
  };
};

/**
 *
 * @param {object} userParams
 * @param {boolean} [clearBeforeGet=false] - define if we need to clear files storage before request new files
 * @param {string} [cancelToken=''] - abort request
 * @returns {Function}
 */
export const getOwnershipFilesEffect = (
  userParams = {},
  clearBeforeGet = false,
  cancelToken
) => {
  return async (dispatch, getState) => {
    if (clearBeforeGet) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }

    const state = getState();
    const securities = R.path(['home', 'getFiles', 'securities'], state);

    if (userParams.page === 1 && userParams.order_by) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }
    dispatch(actions.getFiles());

    const url = `${API_GET_FILE}/delegate/ownership`;

    await authRequest
      .get(
        url,
        {
          params: {
            ...userParams,
            ...createSecurityReqObj(securities),
          },
        },
        { options: { cancelToken } }
      )
      .then((result) => {
        const files = {
          ...result,
          data: {
            data: result.data.data.map((file) => ({
              ...file,
              folderId: 'ownership',
            })),
          },
        };
        dispatch(uploadActions.endUpload());
        dispatch(actions.getFilesSuccess(files));
      })
      .catch((e) => {
        dispatch(uploadActions.endUpload());
        dispatch(actions.getFilesFailure(e));
      });
  };
};
/**
 *
 * @param {number} folderId
 * @param {object} userParams
 * @param {boolean} [clearBeforeGet=false] - define if we need to clear files storage before request new files
 * @param {string} [cancelToken=''] - abort request
 * @returns {Function}
 */
export const getSharedFolderFilesEffect = (
  folderId = null,
  userParams = {},
  clearBeforeGet = false,
  cancelToken
) => {
  return async (dispatch, getState) => {
    if (clearBeforeGet) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }

    const state = getState();
    const securities = R.path(['home', 'getFiles', 'securities'], state);

    if (userParams.page === 1 && userParams.order_by) {
      dispatch(actions.getFilesClear());
      dispatch(actions.getFilesReload());
    }
    dispatch(actions.getFiles());

    const url = `${API_FOLDERS}${`/` + folderId || ''}`;

    await authRequest
      .get(
        url,
        {
          params: {
            ...userParams,
            ...createSecurityReqObj(securities),
          },
        },
        { options: { cancelToken } }
      )
      .then((result) => {
        dispatch(uploadActions.endUpload());
        // TODO temporary fix for getting proper data
        dispatch(
          actions.getFilesSuccess({
            data: {
              count: result.data?.length,
              data: result.data,
            },
          })
        );
      })
      .catch((e) => {
        dispatch(uploadActions.endUpload());
        dispatch(actions.getFilesFailure(e));
      });
  };
};

export const getFilesUpdateFavoriteEffect = async (entity = {}, dispatch) => {
  const url = `${API_GET_FILE}/favorite/toggle/${entity.slug}`;

  try {
    const data = await authRequest.post(url);
    if (window.location.pathname === '/favorites') {
      dispatch(actions.getFilesRemove(data?.data?.data));
    } else {
      dispatch(getFilesUpdateFavorite(data?.data?.data));
    }
  } catch (e) {
    console.warn(e);
  }
};

export const getFilesFavoritesEffect =
  (params, cancelToken, initialCommit) => async (dispatch) => {
    const url = `${API_FILE}/favorites`;
    try {
      const { data } = await authRequest.get(url, null, {
        options: { params, cancelToken },
      });

      if (initialCommit) {
        dispatch(
          updateSorter({
            initialCommit: true,
            option: data.orderBy || 'createdAt',
            dir: data.orderDirection || SORT_DIR.asc,
            page: SORT_PAGE_TYPE.favorite,
          })
        );
      }

      dispatch(
        actions.getFilesSuccess(
          {
            data: {
              count: data.count,
              data: data.data,
            },
          },
          params.page === 1
        )
      );
    } catch (e) {
      console.warn(e);
    }
  };
