// @ts-nocheck
//Refactoring №3
import authRequest from 'utils/request/auth-request';
import axios from 'axios';
import getToken from '../../../utils/auth/get-token';

interface Report {
  comment: string;
  type: number;
  slug: string;
  networkId: number;
  token_address: string;
  public_address: string;
}

export const sendReport = (report: Report) =>
  authRequest.post(
    `${process.env.REACT_APP_API_PATH}/tokenized/report`,
    report
  );
interface MonetizeBody {
  group: string;
  description: string;
  price: string;
  collection: string;
  token: number;
  file?: string | null;
}

export const monetize = (id: null | string, body: MonetizeBody) => {
  const url = `${process.env.REACT_APP_API_PATH}/monetize${id ? `/${id}` : ''}`;
  const formData = new FormData();
  for (const prop in body) {
    formData.append(prop, body[prop]);
  }

  return axios.post(url, formData, {
    headers: {
      'x-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createMonetize = (body: MonetizeBody) => {
  return monetize(null, body);
};

export const updateMonetize = (id: string, body: MonetizeBody) => {
  return monetize(id, body);
};

export type GetMonetize = {
  data: {
    data: {
      id: number;
      group: {
        id: number;
        is_tokenized: boolean;
      };
      price: number;
      description: string;
    };
    json: {
      name: string;
      description: string;
      image: string;
    };
  };
};

export const getMonetize = (
  slug: string,
  collectionAddress: string,
  token: string
) => {
  return authRequest.get(
    `${process.env.REACT_APP_API_PATH}/monetize/${slug}?collection=${collectionAddress}&token=${token}`
  ) as Promise<GetMonetize>;
};
