import authRequest from 'utils/request/auth-request';

export const saveEncryptedFileKeys = async (
  body: any
): Promise<{ data: string }> => {
  // @ts-ignore
  return authRequest.post(
    `${process.env.REACT_APP_API_PATH}/keys/save_encrypted_file_keys`,
    body
  );
};
