export const GET_FILES = 'GET_FILES';
export const GET_FILES_ADD = 'GET_FILES_ADD';
export const GET_FILES_RELOAD = 'GET_FILES_RELOAD';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_CLEAR = 'GET_FILES_CLEAR';
export const GET_FILES_UPDATE = 'GET_FILES_UPDATE';
export const GET_FILES_REMOVE = 'GET_FILES_REMOVE';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';
export const GET_FILES_SORTING_ADD = 'GET_FILES_SORTING_ADD';
export const GET_FILES_SET_SECURITIES = 'GET_FILES_SET_SECURITIES';
export const GET_FILES_CLEAR_SECURITIES = 'GET_FILES_CLEAR_SECURITIES';
export const GET_FILES_UPDATE_FAVORITE = 'GET_FILES_UPDATE_FAVORITE';
export const GET_FILES_UPDATE_LIST = 'GET_FILES_UPDATE_LIST';
export const GET_SORT_UPDATE = 'GET_SORT_UPDATE';
export const UPDATE_FILE_PROP = 'UPDATE_FILE_PROP';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const GET_FILES_ADD_PRELOAD = 'GET_FILES_ADD_PRELOAD';
export const GET_FILES_UPDATE_PRELOAD = 'GET_FILES_UPDATE_PRELOAD';

export const getFiles = () => {
  return {
    type: GET_FILES,
  };
};

export const getFilesAdd = (data) => {
  return {
    type: GET_FILES_ADD,
    payload: {
      data,
    },
  };
};

export const getFilesReload = () => {
  return {
    type: GET_FILES_RELOAD,
  };
};

export const getFilesUpdateList = (list) => {
  return {
    type: GET_FILES_UPDATE_LIST,
    payload: list,
  };
};

export const getFilesSuccess = (res, clear) => {
  return {
    type: GET_FILES_SUCCESS,
    clear,
    payload: res,
  };
};

export const getFilesClear = () => {
  return {
    type: GET_FILES_CLEAR,
  };
};

export const getFilesRemove = (data) => {
  return {
    type: GET_FILES_REMOVE,
    payload: {
      data,
    },
  };
};

export const getFilesUpdate = (data) => {
  return {
    type: GET_FILES_UPDATE,
    payload: data,
  };
};

export const getFilesFailure = (err) => {
  return {
    type: GET_FILES_FAILURE,
    payload: err,
  };
};

export const getFilesSortingAdd = (data) => {
  return {
    type: GET_FILES_SORTING_ADD,
    payload: data,
  };
};

export const getFilesSortingRemove = () => {
  return {
    type: GET_FILES_SORTING_ADD,
  };
};

export const getFilesSetSecurities = (securities) => {
  return {
    type: GET_FILES_SET_SECURITIES,
    payload: securities,
  };
};

export const getFilesClearSecurities = () => {
  return {
    type: GET_FILES_CLEAR_SECURITIES,
  };
};

export const getFilesUpdateFavorite = (entity) => {
  return {
    type: GET_FILES_UPDATE_FAVORITE,
    payload: entity,
  };
};

export const getSortUpdate = (sort) => {
  return {
    type: GET_SORT_UPDATE,
    payload: sort,
  };
};

export const updateFileProperty = (slug, key, value) => {
  return {
    type: UPDATE_FILE_PROP,
    payload: { slug, key, value },
  };
};

export const updateCurrentPage = (page) => {
  return {
    type: UPDATE_CURRENT_PAGE,
    payload: page,
  };
};

export const getFilesAddPreload = (data) => {
  return {
    type: GET_FILES_ADD_PRELOAD,
    payload: data,
  };
};

export const getFilesUpdatePreload = (data) => {
  return {
    type: GET_FILES_UPDATE_PRELOAD,
    payload: data,
  };
};