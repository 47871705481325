import axios from 'axios';
import getToken from 'utils/auth/get-token';
import { API_CHECK_SECURITY } from 'constants/api-urls';

/**
 * @summary Send request to server with security data, and return token if success
 * @function
 * @public
 * @param {string} securityType
 * @param {string} value
 * @returns {Function}
 */
export function entityCheckSecurityEffect(securityType, value) {
  return () => {
    const url = `${API_CHECK_SECURITY}?securityType=${securityType}`;
    const data = new FormData();
    data.append('data', value);

    return axios
      .create({
        headers: { 'X-Token': getToken() },
      })
      .post(url, data);
  };
}
