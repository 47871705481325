//Refactoring №3
import axios from 'axios';

import getToken from 'utils/auth/get-token';
import { ipfsLink } from 'utils/ipfs/link';
import { API_PUB } from 'constants/api-urls';

// Do not use this request
// eslint-disable-next-line no-unused-vars
// export default async (
//   fileId,
//   cancelToken,
//   useBlob,
//   oneTimeTokenBody,
//   isSvg = false,
//   pubEndpoint
// ) => {
//   const token = getToken();
//   const {
//     data: { jwt_ott, user_tokens, gateway },
//   } = await getDownloadOTT([{ slug: fileId }]);

//   const url = token
//     ? `${gateway.url}/download/${fileId}/${FILE_ACTION_TYPES.VIEW}`
//     : `${pubEndpoint}/pub/download/${fileId}`;
//   let chunks = [];

//   const fetchedResource = await fetch(url, {
//     method: 'get',
//     headers: {
//       'one-time-token': user_tokens.token,
//       'X-Download-OTT-JWT': jwt_ott,
//     },
//   });

//   if (useBlob) {
//     return fetchedResource.blob();
//   }

//   if (isSvg) {
//     return await fetchedResource.text();
//   }

//   const reader = await fetchedResource.body.getReader();
//   return reader.read().then(function processText({ done, value }) {
//     if (done) {
//       const blob = new Blob([chunks]);
//       return URL.createObjectURL(blob);
//     }
//     const tempArray = new Uint8Array(chunks.length + value.length);
//     tempArray.set(chunks);
//     tempArray.set(value, chunks.length);
//     chunks = tempArray;

//     return reader.read().then(processText);
//   });
// };

export const getPublicSharedFileContent = async (
  fileId,
  cancelToken,
  useBlob = false,
  gateway
) => {
  const url = `${gateway.url}/pub/download/${fileId}`;
  const token = getToken();
  const config = {
    headers: {
      'x-token': token && token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  if (useBlob) {
    config.responseType = 'blob';
  }

  return axios
    .get(url, config)
    .then((response) => {
      if (useBlob) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      }

      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const getIpfsFileContent = (ipfsKey) => {
  return axios
    .get(ipfsLink(ipfsKey), {
      responseType: 'blob',
    })
    .then((response) => {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(response.data);
    });
};

export const getPublicSharedFilesContent = async (
  files,
  cancelToken,
  useBlob = false
) => {
  const filesUrl = files.map((item) => `files[]=${item.slug}`).join('&');
  const url = `${API_PUB}/mixed/download?${filesUrl}`;
  const config = {
    cancelToken,
  };

  if (useBlob) {
    config.responseType = 'blob';
  }

  return axios
    .get(url, config)
    .then((response) => {
      if (useBlob) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      }

      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const getPublicSharedFolderContent = async (
  folderId,
  cancelToken,
  useBlob = false
) => {
  const url = `${API_PUB}/folders/${folderId}`;
  const config = {
    cancelToken,
  };

  if (useBlob) {
    config.responseType = 'blob';
  }

  return axios
    .get(url, config)
    .then((response) => {
      if (useBlob) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      }

      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

// export const getDocPreviewFileContent = async (
//   fileId,
//   cancelToken,
//   useBlob = false
// ) => {
//   const {
//     data: {
//       jwt_ott,
//       user_tokens: { token: oneTimeToken },
//       gateway,
//     },
//   } = await getDownloadOTT([{ slug: fileId }]);

//   const url = `${gateway.url}/doc/preview/${fileId}`;
//   const config = {
//     cancelToken,
//     headers: {
//       'one-time-token': oneTimeToken,
//       'X-Download-OTT-JWT': jwt_ott,
//       'Content-Type': 'application/json; charset=utf-8',
//     },
//   };

//   if (useBlob) {
//     config.responseType = 'blob';
//   }

//   return axios
//     .get(url, config)
//     .then((response) => {
//       if (useBlob) {
//         const urlCreator = window.URL || window.webkitURL;
//         return urlCreator.createObjectURL(response.data);
//       }

//       return response.data;
//     })
//     .catch((response) => {
//       throw response;
//     });
// };
// export const getPubDocFileContent = async (
//   fileId,
//   cancelToken,
//   useBlob = false,
//   gateway
// ) => {
//   const url = `${gateway.url}/pub/doc/preview/${fileId}`;
//   const token = getToken();
//   const config = {
//     cancelToken,
//     headers: {
//       'x-token': token && token,
//       'Content-Type': 'application/json; charset=utf-8',
//     },
//   };

//   if (useBlob) {
//     config.responseType = 'blob';
//   }

//   return axios
//     .get(url, config)
//     .then((response) => {
//       if (useBlob) {
//         const urlCreator = window.URL || window.webkitURL;
//         return urlCreator.createObjectURL(response.data);
//       }

//       return response.data;
//     })
//     .catch((response) => {
//       throw response;
//     });
// };
