import axios from 'axios';
import getToken from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';
import { getDownloadOTT } from '../files-upload/upload-file.effect';
import { API_PUB } from 'constants/api-urls';

export default async (
  fileId,
  width,
  height,
  fit,
  cancelToken,
  type = undefined
) => {
  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
    },
  } = await getDownloadOTT([{ slug: fileId }]);
  let url;
  if (type.includes('doc')) {
    url = `${gateway.url}/doc/preview/${fileId}`;
    return authRequest
      .get(url, null, {
        options: {
          responseType: 'blob',
          cancelToken,
          headers: { 'X-Token': getToken(), 'One-Time-Token': oneTimeToken, 'X-Download-OTT-JWT': jwt_ott },
        },
      })
      .then((response) => {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      })
      .catch((response) => {
        throw response;
      });
  } else {
    url = `${gateway.url}/preview/${fileId}`;

    return authRequest
      .get(url, null, {
        options: {
          responseType: 'blob',
          cancelToken,
        },
        headers: {
          'one-time-token': oneTimeToken,
          'X-Download-OTT-JWT': jwt_ott,
        },
      })
      .then(async (response) => {
        // TO DO: after server change delete
        if (
          response.data.size === 34 &&
          response.data.type === 'application/json'
        )
          throw 'not found';
          const text = await response.data.text();

        if (text.startsWith('data:image')) {
          return text;
        } else {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(response.data);
        }
      })
      .catch((response) => {
        throw response;
      });
  }
};

export const getPublicSharedImagePreview = async (
  fileId,
  width,
  height,
  fit = 'crop',
  cancelToken
) => {
  const url = `${API_PUB}/preview/${fileId}?q=70&w=${width}&h=${height}&fit=${fit}`;

  return axios
    .get(url, {
      responseType: 'blob',
      cancelToken,
      headers: { 'X-Token': getToken() },
    })
    .then((response) => {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(response.data);
    })
    .catch((response) => {
      throw response;
    });
};
export const getPublicISharedImagePreview = async (
  fileId,
  cancelToken,
) => {
  const { data: { gateway } } = await getDownloadOTT([{ slug: fileId }]);
  const url = `${gateway.url}/pub/preview/${fileId}`;

  return axios
    .get(url, {
      responseType: 'blob',
      cancelToken,
      headers: { 'X-Token': getToken() },
    })
    .then((response) => {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(response.data);
    })
    .catch((response) => {
      throw response;
    });
};
