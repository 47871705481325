//Refactoring №2
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { useHistory, useLocation } from 'react-router-dom';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { DropTarget, DragSource } from 'react-dnd';
import moment from 'moment';
import { isEmpty } from 'ramda';
import { isNull } from 'lodash';
import axios from 'axios';

import ContextMenu from 'components/context-menu';
import SharedIcon from 'components/svg/shared';
import SecuredIcon from 'components/svg/secured';
import DiamondIcon from 'components/svg/diamond';
import EncryptedIcon from 'components/svg/encrypted';
import ContextMenuButton, {
  contextMenuButtonStyle,
} from 'components/ContextMenuButton';
import Checkbox, { checkboxStyle } from 'components/Checkbox';
import Spinner from 'components/Spinners';
import Tooltip from 'components/shared/Tooltip/tooltip';
import CustomFolderIcon from 'components/CustomFileIcon/CustomFolderIcon';
import { ReactComponent as SharedUserIcon } from 'static/assets/svg/shared-user-icon.svg';
import { ReactComponent as StareIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/star.svg';
import {
  FileSource,
  collectDragSource,
} from 'containers/shared/file-container';
import Users from 'containers/main/EntitySettingsContainer/components/InfoTab/MetaTab/users';
import CreateEntityModal from 'containers/header/header-container/create-entity-modal';
import Modal from 'containers/modal/Container';

import {
  handleEditModal,
  handleGeoModal,
  handleDeleteModal,
} from 'features/modals/modal-slice';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import updateFileEffect from 'store/home/effects/file/file-update.effect';
import UserContext from 'store/home/contexts/user-context';
import { isMember } from 'store/home/selectors';
import { selectNeyrAIFiles } from 'features/neyra';
import {
  entityCheckIsEmptyEffect,
  entityCheckIsEmptyInTrashEffect,
} from '../../store/home/effects/entity-actions/entity-check-is-empty.effect';
import getImagePreviewEffect from 'store/home/effects/file/get-image-preview.effect';
import { getDownloadOTT } from 'store/home/effects';

import { checkDeviceAndScroll } from 'utils/browser';
import { isMobile } from 'utils/mobile';
import { useClickHanler } from 'utils/hooks/use-click-handler';
import calculatePositionByElement from 'utils/calculate-position-by-element';
import { validationRegExp } from 'utils/validation/regexp';
import { isMetamaskAddress } from 'utils/crypto/validation';
import { extractShareRightsForUser } from 'utils/shares';
import dndTypes from 'config/dnd-types';
import actionsOptions from 'config/actions-options';
import { EMBARGO_MULTISIG_OPTIONS } from 'config/multisig';
import imageFileExtensions from 'config/image-file-extensions';

import style from './style.module.css';

const FolderTarget = {
  drop(props, monitor) {
    const { file } = monitor.getItem();
    const { folder, selectedSlugs } = props;
    const { onDropHandler } = props;
    if (!selectedSlugs.includes(folder?.slug) && file.slug !== folder.slug) {
      if (selectedSlugs.includes(file.slug) || !selectedSlugs.length)
        onDropHandler(folder, file, false);
      else onDropHandler(folder, file, true);
    }

    return {};
  },
};

const FolderSource = {
  ...FileSource,
  beginDrag(props) {
    const { folder, onDragBegin } = props;
    onDragBegin(folder);

    return {
      file: folder,
    };
  },
};

function collectDropTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    didDrop: monitor.didDrop(),
  };
}

export const FolderContainer = ({
  folder,
  isCreate,
  isSelected,
  hasPending,
  viewType,
  isOver,
  connectDropTarget,
  canSelect,
  onEditName,
  isEdit,
  isGuest,
  onCancelEdit,
  fileFolderActionHandlerEffect,
  selectedFoldersRemoveEffect,
  selectedFoldersAddEffect,
  showSharing,
  selectedEntity,
  hideActions,
  renderAsFile,
  trash,
  optionHandler,
  sharedPage,
  isDragged,
  connectDragSource,
  connectDragPreview,
  onForceDoubleClick,
  onCheckChange,
  isPublic,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const { t: contextT } = useTranslation('contextMenu');
  const { t: ownerT } = useTranslation('owner');
  const { user } = useContext(UserContext);
  const [name, setName] = useState('');
  const [showContext, setShowContext] = useState(false);
  const [isFolderEmpty, setIsFolderEmpty] = useState(false);
  const [contextPosition, setContextPosition] = useState({
    top: '10px',
    left: '50%',
  });
  const [folderEntities, setFolderEntities] = useState(0);
  const [folderImages, setFolderImages] = useState([]);
  const dispatch = useDispatch();
  const color = useMemo(
    () => folder?.color?.[folder.color.length - 1],
    [folder]
  );
  const colorName = useMemo(() => color?.name, [color]);
  const colorHex = useMemo(() => color?.hex, [color]);
  const isSelectedEntity = useMemo(
    () => selectedEntity?.entity?.slug === folder?.slug,
    [selectedEntity, folder]
  );
  const folderItemRef = useRef(null);
  const folderElement = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const sharedUrl = useMemo(
    () => (history.location.pathname.includes('shared') ? 'shared/' : ''),
    [history]
  );
  const hasMetamaskShares = useMemo(
    () =>
      folder.shares.some((item) => isMetamaskAddress(item?.share_right?.input)),
    [folder]
  );
  const hasUserShares = useMemo(
    () =>
      folder.shares.some(
        (item) =>
          validationRegExp.email.test(item?.share_right?.input) ||
          isNull(item?.share_right?.input) ||
          item.type === 1
      ),
    [folder]
  );
  const isShared = useMemo(
    () => hasMetamaskShares || hasUserShares,
    [hasMetamaskShares, hasUserShares]
  );
  const created = useMemo(
    () => (folder?.created_at ? moment.unix(folder.created_at) : moment()),
    [folder]
  );
  const sharePermissions = extractShareRightsForUser(user?.id, folder?.shares);
  const isGuestAccount = useMemo(
    () =>
      (isGuest || user.isGuest) &&
      (user.role !== 'ROLE_OWNER' || location.pathname === '/sharing/with-me'),
    [isGuest, user, location]
  );
  const isMinted = useMemo(
    () => !!folder.entry_groups.some((item) => item.is_tokenized),
    [folder]
  );
  const isRightBarOpenClassName = useMemo(
    () => selectedEntity?.accessAllow,
    [selectedEntity]
  );

  const neyraFolder = useSelector(selectNeyrAIFiles).find((item) => (item.uid === folder.slug));

  const isNeyraFolder = !!neyraFolder
  const isNeyraFolderProccess = isNeyraFolder && !neyraFolder?.ai;

  useEffect(() => {
    if (location.state?.parentSlug) {
      const isCorrectParent = folder.slug === location.state.parentSlug;
      if (isCorrectParent) {
        fileFolderActionHandlerEffect(folder, actionsOptions.goToTokenization);
      }
    }
  }, [location.state?.parentSlug]);

  const getFolderFileSlug = async (files) => {
    const previewUpdateDay = 1689920400;
    const blobsArray = [];

    for (const file of files) {
      if (blobsArray.length === 3) {
        break;
      }

      const isImageValid =
        file.type === 1 &&
        imageFileExtensions.includes(`.${file.extension}`) &&
        file.created_at > previewUpdateDay;

      if (isImageValid) {
        if (!file.is_clientside_encrypted) {
          const fileSignal = axios.CancelToken.source();
          const data = await getImagePreviewEffect(
            file.slug,
            32,
            62,
            'crop',
            fileSignal.token,
            file.mime
          );
          blobsArray.push(data);
        } else {
          const downloadOTTResponse = await getDownloadOTT([
            { slug: file.slug },
          ]);
          const gateway = downloadOTTResponse.data.gateway;
          const res = await axios.get(`${gateway.url}/preview/${file.slug}`, {
            headers: {
              'one-time-token': downloadOTTResponse.data.user_tokens.token,
              'X-Download-OTT-JWT': downloadOTTResponse.data.jwt_ott,
            },
          });
          blobsArray.push(res.data);
        }
      }
    }

    setFolderImages(blobsArray);
  };

  useEffect(() => {
    if (!isCreate) {
      setName(folder.name);
    }
  }, [folder, isCreate]);

  useEffect(() => {
    if (!trash) {
      entityCheckIsEmptyEffect(folder.slug, 1).then((data) => {
        setFolderEntities(data?.count);
        getFolderFileSlug(data?.data);
      });
    } else {
      entityCheckIsEmptyInTrashEffect(folder.slug, 1).then((count) => {
        setFolderEntities(count);
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }, []);

  const onEndEditHandler = useCallback(
    (newName) => {
      if (isCreate) {
        onEditName(newName);
      } else if (isEdit) {
        dispatch(
          updateFileEffect(
            folder,
            () => dispatch(handleEditModal(false)),
            null,
            {
              update: { name: newName },
              updatedPropsList: ['name'],
            }
          )
        );
      }
    },
    [isCreate, isEdit, folder]
  );

  const onCancelEditHandler = useCallback(() => {
    dispatch(handleEditModal(false));
    if (isCreate) {
      onCancelEdit();
    }
  }, [isCreate]);

  const onActionHandler = async (option) => {
    if (
      [
        actionsOptions.removeFromTrash.type,
        actionsOptions.restoreFromTrash.type,
        actionsOptions.removeFromShared.type,
        actionsOptions.removeShared.type,
        actionsOptions.showMove.type,
        actionsOptions.myAI.type,
      ].includes(option.type)
    ) {
      optionHandler(folder, option);
      return;
    }
    if ([actionsOptions.remove.type].includes(option.type)) {
      dispatch(handleDeleteModal({ isOpen: true, entity: folder }));
      return;
    }
    if (
      [
        actionsOptions.guardians.type,
        actionsOptions.geoSecurity.type,
        actionsOptions.accessPreferences.type,
      ].includes(option.type)
    ) {
      fileFolderActionHandlerEffect(folder, option, { skipGetData: true });
      return;
    }
    if (option.type === actionsOptions.geoSecurity.type) {
      dispatch(handleGeoModal({ modal: true, entity: folder }));
      return;
    }
    if (option.type === actionsOptions.nft.type) {
      if (!folderEntities) {
        setIsFolderEmpty(true);
        return;
      }
      fileFolderActionHandlerEffect(folder, actionsOptions.goToTokenization);
      return;
    }

    fileFolderActionHandlerEffect(folder, option);
  };

  const onFolderSelectHandler = useCallback(() => {
    if (isSelected) {
      onCheckChange(false, folder);
      selectedFoldersRemoveEffect(folder);
      return;
    }

    selectedFoldersAddEffect({
      entities: folder,
    });
    onCheckChange(true, folder);
  }, [isSelected, folder]);

  const folderClickHandler = useCallback(
    (e) => {
      if (isFolderEmpty) return;
      e.stopPropagation();
      const { goToSettings } = actionsOptions;
      const target = e.target;
      const parent = target.parentElement;
      const targetClasses = target.className;
      const parentClasses = parent.className;
      const isActionElem =
        !(targetClasses instanceof SVGAnimatedString) &&
        (targetClasses.indexOf('action') >= 0 ||
          parentClasses.indexOf('action') >= 0);
      const isCheckBoxElem =
        !(targetClasses instanceof SVGAnimatedString) &&
        (targetClasses.indexOf('checkbox') >= 0 ||
          parentClasses.indexOf('select-item-checkbox') >= 0);

      if (
        !isActionElem &&
        !isCheckBoxElem &&
        !e.shiftKey &&
        !isEdit &&
        !user.isGuest
      ) {
        fileFolderActionHandlerEffect(folder, goToSettings);
      }

      if (e.shiftKey) {
        onFolderSelectHandler();
      }
    },
    [isFolderEmpty, isEdit, user, folder]
  );

  const folderDoubleClickHandler = (e) => {
    const { goToFolder } = actionsOptions;
    checkDeviceAndScroll();
    if (onForceDoubleClick) {
      onForceDoubleClick(folder);
      return;
    }

    if (trash) return;

    if (e.shiftKey && !isEdit && !isCreate) {
      onFolderSelectHandler();
      return;
    }
    const targetClassName = e.target.className;
    if (typeof targetClassName === 'string') {
      const isActionDotsClick =
        targetClassName.indexOf('action') >= 0 ||
        targetClassName.indexOf('checkbox') >= 0;

      if (isActionDotsClick) return;
    }

    if (!isEdit && !isCreate) {
      if (isEmpty(folder.securities)) {
        history.push(`/folder/${sharedUrl}${folder.slug}`, {
          sharedPage: sharedPage,
          isMintedParent: isMinted,
          folderTitle: folder.name,
          folderSlug: folder.slug,
        });

        return;
      }
      const data = {
        url: `/folder/${sharedUrl}${folder.slug}`,
        folderId: folder.slug,
        sharedPage,
        isMintedParent: isMinted,
      };
      fileFolderActionHandlerEffect(folder, goToFolder, data, history);
    }
  };

  const onClickHandler = useClickHanler(
    folderDoubleClickHandler,
    folderClickHandler
  );

  const contextOptions = () => {
    switch (true) {
      case trash:
        return [
          actionsOptions.restoreFromTrash,
          actionsOptions.removeFromTrash,
        ].filter(contextFilter);
      case isGuestAccount:
        return [];
      case isPublic:
        return [];
      case isMember(user) && isBetaEnviroment:
        return [
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          actionsOptions.nft,
          showSharing ? actionsOptions.removeFromShared : null,
        ].filter(contextFilter);
      case isMember(user):
        return [
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          actionsOptions.nft,
          actionsOptions.guardians,
          showSharing ? actionsOptions.removeFromShared : null,
        ].filter(contextFilter);

      case isBetaEnviroment:
        return [
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          actionsOptions.nft,
          actionsOptions.geoSecurity,
          showSharing ? actionsOptions.removeFromShared : null,
        ].filter(contextFilter);
      default:
        return [
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          actionsOptions.nft,
          actionsOptions.geoSecurity,
          actionsOptions.guardians,
          showSharing ? actionsOptions.removeFromShared : null,
        ].filter(contextFilter);
    }
  };

  const onRightFileClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.clientY === 0 || e.clientX === 0) return;
      const position = calculatePositionByElement(e, contextOptions().length);
      setContextPosition(position);
      setShowContext(true);
    },
    [contextOptions]
  );

  const onClickOutsideContext = useCallback(() => {
    setShowContext(false);
  }, []);

  const isBetaEnviroment = useMemo(
    () => process.env.REACT_APP_ENVIROMENT === 'BETA',
    []
  );

  const contextFilter = useCallback(
    (option) => {
      if (!option) return false;
      if (isMultisigActivated && !isMultisigPartisipant) {
        const embargoOptions = EMBARGO_MULTISIG_OPTIONS;

        return !embargoOptions.includes(option);
      }

      return true;
    },
    [isMultisigActivated, isMultisigPartisipant]
  );

  const renderContext = useMemo(
    () =>
      showContext ? (
        <Portal>
          <ContextMenu
            onClickOutside={onClickOutsideContext}
            contextPosition={contextPosition}
            actionHandler={onActionHandler}
            options={contextOptions()}
            permission={showSharing ? sharePermissions : false}
            data-test="folder-container_context-menu_button"
            entity={folder}
          />
        </Portal>
      ) : null,
    [showContext, contextPosition, showSharing, sharePermissions, folder]
  );

  const renderFolderName = useCallback(() => {
    const maxLength = 16;
    const sliceName = () => folder?.name?.slice(0, maxLength).trim() + '...';
    return folder?.name?.length > maxLength && viewType !== 'small-row' ? (
      <>
        {sliceName()}
        <Tooltip className="folder-tooltip" text={folder.name} />
      </>
    ) : (
      folder?.name
    );
  }, [folder, viewType]);

  const handleCloseEmptyFolderModal = useCallback(() => {
    setIsFolderEmpty(false);
  }, []);

  const renderData = useCallback(() => {
    if (!folder?.name) return null;

    return (
      <div className="folder__data">
        <Modal
          overlayClassName="Overlay"
          title={ownerT('tokenized.cannotTokenizeEmptyFolder')}
          isOpen={isFolderEmpty}
          onClose={handleCloseEmptyFolderModal}
          primaryButtonProps={{
            text: ownerT('tokenized.continue'),
            onClick: handleCloseEmptyFolderModal,
          }}
        >
          {ownerT('tokenized.addFiles')}
        </Modal>
        <div className="folder__title" data-test="folder-container_folder_name">
          {renderFolderName()}
        </div>
        {viewType !== 'small-row' && (
          <div className="folder__desc">
            {`${ownerT('tokenized.objectsInFolder')}: ${folderEntities}`}
          </div>
        )}
        {showSharing && <div className="folder__desc" />}
      </div>
    );
  }, [isFolderEmpty, viewType, folderEntities, showSharing, folder]);

  const renderEditForm = () => {
    const label = isCreate
      ? contextT('main.createFolder.create')
      : contextT('main.createFolder.rename');
    const renameNodeEffect = (node) => {
      node.focus();
      node.setSelectionRange(0, name.length);
    };

    return (
      <CreateEntityModal
        closeModal={onCancelEditHandler}
        modalIsOpen={isEdit}
        onCreate={onEndEditHandler}
        title={`${label} ${contextT('main.createFolder.folder')}`}
        contentLabel={`${label} ${contextT('main.createFolder.folder')}`}
        inputPlaceholder={contextT('main.createFolder.enterName')}
        defaultValue={name}
        acceptButtonText={label}
        nodeEffect={!isCreate && renameNodeEffect}
        name={contextT('main.createFolder.folderName')}
      />
    );
  };

  const folderCreated = useMemo(
    () =>
      viewType === 'small-row' && (
        <div className="folder__created">
          {created.format('MMM DD, YYYY, H:mma')}
        </div>
      ),
    [viewType, created]
  );

  const aiHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onActionHandler(actionsOptions.myAI)
  }

  const renderActions = () => {
    if (hideActions) return null;
    const canRender = !isEdit && !isCreate && !hideActions;

    if (viewType === 'square') {
      return (
        <button
          onClick={aiHandler}
          className={cn(
            'folder-action-avatar',
            folder?.user_favorites?.length && 'folder-action-avatar-active',
            (isNeyraFolderProccess || (isNeyraFolder && !isNeyraFolderProccess)) && ['file-action-avatar-show', 'file-action-avatar-active'],
          )}
        >
          { isNeyraFolderProccess ? <Spinner /> : <StareIcon /> } 
        </button>
      )
    }

    return (
      canRender && (
        <ContextMenuButton
          className="file-action"
          onClick={onRightFileClickHandler}
          buttonStyle={contextMenuButtonStyle.DARK}
        />
      )
    );
  };

  const shared = useMemo(
    () =>
      viewType === 'small-row' &&
      (!isMobile ? (
        <div className="folder__shared">
          {folder?.shares?.length
            ? `${folder.shares.length} ${ownerT('common.people')}`
            : '-'}
        </div>
      ) : (
        <div className="folder__shared">
          {folder.shares.length} <SharedUserIcon />
        </div>
      )),
    [viewType, isMobile, folder]
  );

  const renderSize = useMemo(
    () =>
      viewType === 'small-row' && (
        <div className="folder__size" data-test="folder-container_size_type">
          -
        </div>
      ),
    [viewType]
  );

  const renderEncryptIcon = useMemo(
    () =>
      viewType === 'small-row' &&
      folder?.is_clientside_encrypted && (
        <div className={style.encryptIcon}>
          <EncryptedIcon color={'var(--iconColor)'} />
        </div>
      ),
    [viewType, folder]
  );

  const folderSecurity = useMemo(
    () =>
      viewType === 'small-row' && (
        <div
          className="folder__secured"
          data-test="folder-container_secured_type"
        >
          {folder?.securities?.length > 0
            ? folder.securities
                .map((security) => security.keyword.toUpperCase())
                .join(', ')
            : 'NO'}
        </div>
      ),
    [viewType, folder]
  );

  const folderClassNames = cn({
    'folder animated fadeIn': true,
    'file-non-display-option':
      isRightBarOpenClassName && viewType === 'small-row',
    folder_square: viewType === 'square',
    folder_small_row: viewType === 'small-row',
    folder_file: viewType === 'small-row' && renderAsFile,
    folder_edited: isEdit,
    folder_over: isOver,
    folder_pending: hasPending,
    folder_selected: isSelected,
    'selected-entity': isSelectedEntity,
    'user-popup-position-container': true,
    folder_scaled: isDragged,
  });

  const iconClassNames = cn(
    {
      icon__img: true,
      'icon__img--folder': true,
    },
    colorName
  );

  const content = renderAsFile ? (
    <section
      role="button"
      tabIndex={0}
      className={folderClassNames}
      onClick={onClickHandler}
      onContextMenu={onRightFileClickHandler}
      ref={folderElement}
      data-test="folder-container_file_actions"
    >
      {viewType === 'square' && (
        <div className="folder_square_dots" >
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
        </div>
      )}
      <div className="folder__first-info">
        {canSelect && (
          <div
            role="button"
            tabIndex={0}
            className="file__checkbox checkbox"
            onClick={onFolderSelectHandler}
            data-test="folder-container_file_checkbox"
          />
        )}
        <div className="folder__icon icon">
          <div className={iconClassNames}>
            <CustomFolderIcon
              color={colorHex}
              viewType={viewType}
              folderEntities={folderEntities}
              folderImages={folderImages}
            />
          </div>
        </div>
      </div>
      {renderData()}
      {renderEncryptIcon}
      {folderCreated}
      {shared}
      {folderSecurity}
      {renderSize}
      {renderContext}
      {(isEdit || isCreate) && renderEditForm()}
    </section>
  ) : (
    <section
      role="button"
      tabIndex={0}
      className={folderClassNames}
      onClick={onClickHandler}
      onContextMenu={onRightFileClickHandler}
      ref={folderElement}
      data-test={`folder-container_folder_actions[${folder.slug}]`}
    >
      {viewType === 'square' && (
        <div onClick={onRightFileClickHandler} className="folder_square_dots" >
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
        </div>
      )}
      <div className="folder__first-info">
        {canSelect && !isMultisigActivated && (
          <Checkbox
            inputStyle={checkboxStyle.LIGHT}
            className="select-item-checkbox"
            onClick={onFolderSelectHandler}
            checked={isSelected}
            data-test="folder-container_folder_checkbox"
          />
        )}
        <div className="folder__icon icon">
          <div className={iconClassNames}>
            <CustomFolderIcon
              color={colorHex}
              viewType={viewType}
              folderEntities={folderEntities}
              folderImages={folderImages}
            />
          </div>
        </div>
      </div>
      {renderData()}
      {renderEncryptIcon}
      {folderCreated}
      {shared}
      {folderSecurity}
      {renderSize}
      {(isEdit || isCreate) && renderEditForm()}
      {renderActions()}
      {renderContext}
      {(viewType === 'square' || viewType === 'row') && folder && (
        <div className="folder__secured-shared-container">
          {isShared && (
            <div className="folder__secured-shared">
              <SharedIcon />
            </div>
          )}
          {isMinted && (
            <div className="folder__secured-shared">
              <DiamondIcon
                width={17}
                height={18}
                viewBoxWidth={22}
                viewBoxHeight={20}
              />
            </div>
          )}
          {folder.securities.length > 0 && (
            <div className="folder__secured-shared">
              <SecuredIcon />
            </div>
          )}
          {viewType === 'row' && (
            <Users
              users={folder.shares?.filter((share) => share.type === 3)}
              color={color}
              userPopupRef={folderItemRef}
              maxUsersToShow={3}
            />
          )}
        </div>
      )}
      <div className="user-popup-portal" ref={folderItemRef} />
    </section>
  );

  const wrappedContent =
    viewType === 'square' ? (
      <div className="square__item">{content}</div>
    ) : (
      content
    );

  if (connectDragSource) {
    connectDragSource(folderElement);
  }

  if (connectDropTarget) {
    connectDropTarget(folderElement);
  }
  return <>{wrappedContent}</>;
};

FolderContainer.propTypes = {
  folder: PropTypes.object,
  isCreate: PropTypes.bool,
  isSelected: PropTypes.bool,
  showSharing: PropTypes.bool,
  viewType: PropTypes.string,
  onEditName: PropTypes.func,
  hasPending: PropTypes.bool,
  onDropHandler: PropTypes.func,
  onCancelEdit: PropTypes.func,
  canSelect: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  selectedSlugs: PropTypes.array,
  isOver: PropTypes.bool,
  isEdit: PropTypes.bool,
  dndTypes: PropTypes.object,
  hideActions: PropTypes.bool,
  renderAsFile: PropTypes.bool,
  fileFolderActionHandlerEffect: PropTypes.func,
  selectedFilesAddEffect: PropTypes.func,
  selectedFilesRemoveEffect: PropTypes.func,
  openNFTModal: PropTypes.func,
  trash: PropTypes.bool,
  optionHandler: PropTypes.func,
  sharedPage: PropTypes.bool,
  canDrag: PropTypes.bool,
  onDragBegin: PropTypes.func,
  onDragEnd: PropTypes.func,
  onCheckChange: PropTypes.func,
  onForceDoubleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isDragged: PropTypes.bool,
  isPublic: PropTypes.bool,
};

FolderContainer.defaultProps = {
  showSharing: false,
  hasPending: false,
  isSelected: false,
  isCreate: false,
  isEdit: false,
  canSelect: true,
  hideActions: false,
  renderAsFile: false,
  trash: false,
  sharedPage: false,
  optionHandler: () => {},
  fileFolderActionHandlerEffect: () => {},
  selectedFolderAddEffect: () => {},
  selectedFolderRemoveEffect: () => {},
  openNFTModal: () => {},
  onCheckChange: () => {},
  onForceDoubleClick: false,
  canDrag: false,
  isDragged: false,
  selectedSlugs: [],
  isPublic: false,
};

export default React.memo(
  DragSource(
    dndTypes.FILE,
    FolderSource,
    collectDragSource
  )(DropTarget(dndTypes.FILE, FolderTarget, collectDropTarget)(FolderContainer))
);
