/* eslint-disable */
import axios from 'axios';
import UAuth from '@uauth/js';

import {
  API_AUTH,
  API_SIGN_IN_METAMASK,
  API_PUB_KEY_SAVE,
  NEIRA_AI_API,
} from 'constants/api-urls';

import authRequest from 'utils/request/auth-request';
import setToken from 'utils/auth/set-token';
import { userWorkspaces, switchWorkspace } from 'store/workspace/effects';
import { workspaceSecurityCheck } from 'store/home/actions/file/entities-security-check.actions';
import { profileLogin } from 'features/profile/profileSlice';
import { store } from 'store/root-store';
import USER_ROLE_TYPES from 'config/user-role-types';
import { getSearchParamByLocation } from 'utils/url/get-search-param-by-location';
import getToken from 'utils/auth/get-token';

export const loginUsernameEffect = (
  username,
  password,
  history,
  slug,
  name
) => {
  const searchParams = new URLSearchParams(history?.location?.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const redirectUrl = !isoauth ? '/main' : `/oauth${history?.location?.search}`;
  const body = {
    provider: 'email',
    first_name: name,
    password,
    email: username,
  };

  return axios
    .put(`${NEIRA_AI_API}/auth/identity/connect_userv8`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      if (res.data.data) {
        setToken(res.data);
        userWorkspaces().then((data) => {
          data.map(({ workspace, role }) => {
            if (workspace.current)
              if (
                !workspace.workspace_securities ||
                workspace.workspace_securities.length == 0 ||
                !workspace.is_workspace_owner
              ) {
                history &&
                  history.push(
                    role === USER_ROLE_TYPES.GUEST ? '/sharing' : redirectUrl
                  );
              } else
                store.dispatch(
                  workspaceSecurityCheck({
                    data: [...workspace.workspace_securities],
                    nextWorkspace: workspace,
                  })
                );
          });
          store.dispatch(profileLogin());
        });
      }

      return res?.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
};

export const savePubKey = async (publicAddress, publicKey) => {
  const body = { publicAddress, publicKey };
  const token = getToken();
  const headers = {};
  if (token) {
    headers['X-Token'] = token;
  }
  return axios.post(API_PUB_KEY_SAVE, body, {
    headers,
  });
  return authRequest.post(API_PUB_KEY_SAVE, body).catch((error) => {
    // eslint-disable-next-line no-throw-literal
    throw error?.response?.data;
  });
};

export const loginMetamaskClear = (publicAddress, signature) => {
  return axios
    .post(API_SIGN_IN_METAMASK, { publicAddress, signature })
    .then((res) => {
      const token = res.data.token;
      if (token) {
        setToken(res);
      }
    });
};
export const handleMetamaskLogin = ({
  res,
  dispatch,
  redirectUrl,
  history,
}) => {
  setToken(res);
  dispatch(profileLogin());
  userWorkspaces().then((data) => {
    data.map(({ workspace }) => {
      if (workspace.current)
        if (
          !workspace.workspace_securities ||
          workspace.workspace_securities.length == 0 ||
          !workspace.is_workspace_owner
        ) {
          const fallbackUrl = localStorage.getItem('fallbackUrl') || '';
          const wsId = parseInt(
            getSearchParamByLocation(
              new URL(window.location.origin + fallbackUrl),
              'workspaceId'
            )
          );
          if (wsId && workspace.id !== wsId) {
            switchWorkspace(wsId).then(() => {
              history && history.push('/chat');
            });
          } else {
            history && history.push(redirectUrl);
          }
        } else {
          dispatch(
            workspaceSecurityCheck({
              data: [...workspace.workspace_securities],
              nextWorkspace: workspace,
            })
          );
        }
    });
  });
};

export const loginMetamask = (
  publicAddress,
  signature,
  history,
  dispatch,
  slug,
  redirectUrl,
  token,
  forceReload
) => {
  const body = {
    publicAddress,
    signature,
    slug,
  };
  if (!slug) delete body.slug;
  const headers = {};
  if (token) {
    headers['X-Token'] = `Bearer ${token}`;
  }
  if (forceReload) {
    setTimeout(() => {
      if (history) {
        history.push('/chat');
      } else {
        window.location.href = '/chat';
      }
    }, 1000);
  }

  return axios
    .post(API_SIGN_IN_METAMASK, body, {
      headers,
    })
    .then((res) => {
      dispatch(profileLogin());
      setTimeout(() => {
        userWorkspaces().then((data) => {
          data.map(({ workspace }) => {
            if (workspace.current)
              if (
                !workspace.workspace_securities ||
                workspace.workspace_securities.length == 0 ||
                !workspace.is_workspace_owner
              ) {
                const fallbackUrl = localStorage.getItem('fallbackUrl') || '';
                const wsId = parseInt(
                  getSearchParamByLocation(
                    new URL(window.location.origin + fallbackUrl),
                    'workspaceId'
                  )
                );
                if (wsId && workspace.id !== wsId) {
                  switchWorkspace(wsId).then(() => {
                    history && history.push('/chat');
                  });
                } else {
                  history && history.push(redirectUrl);
                }
              } else {
                dispatch(
                  workspaceSecurityCheck({
                    data: [...workspace.workspace_securities],
                    nextWorkspace: workspace,
                  })
                );
              }
          });
        });
      }, 4000);
      return res;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
};

export const loginPhantom = (publicAddress, signature, history, dispatch) => {
  return axios
    .post(process.env.REACT_APP_SIGN_IN_PHANTOM, {
      publicAddress,
    })
    .then((res) => {
      const token = res.data.token;
      if (token) {
        setToken(res);
        userWorkspaces().then((data) => {
          data.map(({ workspace }) => {
            if (workspace.current) {
              if (
                !workspace.workspace_securities ||
                workspace.workspace_securities.length == 0 ||
                !workspace.is_workspace_owner
              )
                history && history.push('/main');
              else
                dispatch(
                  workspaceSecurityCheck({
                    data: [...workspace.workspace_securities],
                    nextWorkspace: workspace,
                  })
                );
            }
          });
        });
      }
      return res;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
};

export const getNonceRegistrationEffect = async (publicAddress) =>
  axios
    .post(`${API_AUTH}/nonce`, {
      publicAddress,
    })
    .then((response) => {
      return response?.data?.nonce;
    })
    .catch((req) => {
      return req?.response?.data;
    });

export const getNonceEffect = async (publicAddress, action = undefined) => {
  const data = action ? { publicAddress, action } : { publicAddress };
  return axios
    .post(`${API_AUTH}/nonce`, data)
    .then((response) => {
      return response?.data?.nonce;
    })
    .catch((req) => {
      throw req?.response?.data;
    });
};

export const loginUnstoppableEffect = async (history, redirectUrl) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const uauth = new UAuth({
      clientID: process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID,
      redirectUri: window.location.origin,
    });
    const authorization = await uauth.loginWithPopup();
    const { wallet_address } = authorization.idToken || {};
    const { message, signature } = Object.values(
      authorization?.idToken?.proof
    )[0];
    const login = await axios.post(`${API_AUTH}/login_check_unstoppable`, {
      publicAddress: wallet_address,
      message,
      signature,
    });
    setToken(login);
    history.push(redirectUrl);
  } catch (error) {
    throw error;
  }
};
export const getUnstoppableAdressEfect = async () => {
  const uauth = new UAuth({
    clientID: process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID,
    redirectUri: window.location.origin,
  });
  const authorization = await uauth.loginWithPopup();
  const { wallet_address } = authorization.idToken || {};
  return wallet_address;
};
