//Refactoring №2
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { downloadFileFromSP } from 'gdgateway-client/lib/es5/downloadFile/downloadFileFromSP';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

import { getDownloadOTT } from 'store/home/effects';
import MainContext from 'store/main/context/main-context';
import EncryptedIcon from 'components/svg/encrypted';
import CustomFileIcon from 'components/CustomFileIcon/CustomFileIcon.tsx';
import CustomIconHint from 'components/CustomFileIcon/CustomIconHint';
import CustomFileSmallIcon from 'components/CustomFileIcon/CustomFileSmallIcon';
import Progress from 'containers/shared/progress';
import CustomFolderIcon from 'components/CustomFileIcon/CustomFolderIcon';

import { ReactComponent as PlayIcon } from 'components/CustomFileIcon/assets/smallFileIcons/play.svg';
import { ReactComponent as SharedUserIcon } from 'static/assets/svg/shared-user-icon.svg';

import authRequest from 'utils/request/auth-request';
import { transformSize } from 'utils/storage';
import { isMobile } from 'utils/mobile';

import actionsOptions from 'config/actions-options';
import imageFileExtensions, {
  imageMediaTypesPreview,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import { docMediaTypesPreview } from 'config/docs-file-extensions';
import { videoMediaExtentionPreview } from 'config/video-file-extensions';

import style from './style.module.css';

export const FileContainer = ({
  showFile,
  file,
  isSelected,
  isDragged,
  isEdit,
  disabled,
  viewType,
  selectedEntity,
  showType,
  isPublic,
  forwardedRef,
  uploadingFiles,
}) => {
  const { t: ownerT } = useTranslation('owner');
  const [filePreview, setFilePreview] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [preview, setPreview] = useState('');
  const selectedFile = uploadingFiles.find(
    (upload) => upload?.uploadId === file.uploadId
  );

  const {
    state: { pageName },
  } = useContext(MainContext);
  const fileItemRef = useRef(null);
  const isSelectedEntity = useMemo(
    () => selectedEntity?.entity?.slug === file?.slug,
    [selectedEntity, file]
  );

  const fileSize = transformSize(file?.size);

  const isRightBarOpenClassName = useMemo(
    () => (selectedEntity?.accessAllow ? 'file-non-display-option' : ''),
    [selectedEntity]
  );
  const previewUpdateDay = useMemo(() => 1689920400, []);

  const openHint = () => setShowHint(true);
  const closeHint = () => setShowHint(false);

  useEffect(() => {
    if (file) {
      if (imageMediaTypesPreview.includes(file?.mime)) {
        var reader = new FileReader();

        reader.onload = function (e) {
          setPreview(e.target.result);
        };

        reader.readAsDataURL(file.file);
      }
    }
  }, [file]);

  useEffect(() => {
    if (
      pageName !== 'Deleted' &&
      file?.slug &&
      ((imageFileExtensions.includes(`.${file.extension}`) &&
        !imagesWithoutPreview.includes(`.${file.extension}`)) ||
        docMediaTypesPreview.includes(file.mime) ||
        videoMediaExtentionPreview.includes(file?.extension?.toLowerCase()))
    ) {
      let isPreviewAssigned = false;

      if (file?.thumbnail) {
        setFilePreview(file?.thumbnail);
        isPreviewAssigned = true;
      }
      if (!file?.thumbnail && file?.preview_small) {
        getDownloadOTT([{ slug: file.slug }]).then(async (res) => {
          if (!file.is_on_storage_provider) {
            authRequest
              .get(`${res.data.gateway.url}/preview/${file.slug}`, null, {
                options: {
                  responseType: 'blob',
                },
                headers: {
                  'one-time-token': res.data.user_tokens.token,
                  'X-Download-OTT-JWT': res.data.jwt_ott,
                },
              })
              .then(async (res) => {
                const text = await res.data.text();
                if (text.startsWith('data:image')) {
                  if (file.created_at > previewUpdateDay) {
                    setFilePreview(text);
                  }
                } else {
                  if (file.created_at > previewUpdateDay) {
                    const urlCreator = window.URL || window.webkitURL;
                    setFilePreview(urlCreator.createObjectURL(res.data));
                  }
                }
              });
          } else {
            if (!file.preview_large && !file.preview_small) {
              setFilePreview(null);
              return;
            }
            const fileBlob = await downloadFileFromSP({
              carReader: CarReader,
              url: `${file.storage_provider.url}/${
                file.preview_large ?? file.preview_small
              }`,
              isEncrypted: false,
              uploadChunkSize: 0,
              key: undefined,
              iv: undefined,
              file,
              level: 'root',
            });

            const realBlob = new Blob([fileBlob]);
            const text = await realBlob?.text();
            if (text && text.startsWith('data:image')) {
              if (file.created_at > previewUpdateDay) {
                setFilePreview(text);
              }
            } else {
              if (file.created_at > previewUpdateDay) {
                const urlCreator = window.URL || window.webkitURL;
                setFilePreview(urlCreator.createObjectURL(res.data));
              }
            }
          }
          isPreviewAssigned = true;
        });
      }
      if (isPreviewAssigned) {
        return;
      }
    }
  }, [file.mime, file.service, file.slug, isPublic, viewType, pageName]);

  actionsOptions.viewFile.className = 'context-menu-item-divider';

  const renderInfo = () => {
    return (
      <div className={style.info}>
        <Progress
          total={selectedFile?.size}
          progress={selectedFile?.progress}
          error={selectedFile?.error}
          slug={file?.slug}
          file={file}
        />
        <p className={style.label}>Uploading</p>
      </div>
    );
  };

  const renderIcon = (color) => {
    return file.type === 2 ? (
      <CustomFolderIcon
        color={color?.hex}
        viewType={viewType}
        folderImages={[]}
      />
    ) : (
      <CustomFileIcon
        extension={file.extension}
        color={color?.hex}
        dateCreated={file.created_at}
        onPlayClick={() => {}}
      />
    );
  };

  const renderSmallRow = () => {
    const imageHasPreview =
      !file?.securities?.length && imageMediaTypesPreview.includes(file.mime);
    const color = file.color?.[file.color.length - 1];

    const rowFileClasses = cn({
      file: true,
      file_scaled: isDragged,
      file_small_row: true,
      showed: showFile,
      animated: true,
      fadeIn: true,
      disabled: disabled,
      active: isSelected,
      'is-edit': isEdit,
      'selected-entity': isSelectedEntity,
    });

    const iconClassNames = cn(
      {
        icon__img: true,
        'icon__img--file': true,
      },
      color?.name
    );

    const created = file?.created_at ? moment.unix(file.created_at) : moment();

    return (
      <section
        role="button"
        tabIndex={0}
        ref={forwardedRef}
        className={`${rowFileClasses} ${isRightBarOpenClassName} ${style.rowContainer}`}
        data-test={`file-container_small-row_section[${file.slug}]`}
      >
        <div className="folder_square_dots">
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
        </div>
        <div className="file__container">
          <div className={`${iconClassNames} ${style.smallCustomIcon}`}>
            {imageHasPreview && filePreview || preview ? (
              <img
                key={file.id}
                className="file__image"
                alt={file.name}
                loading="eager"
                src={filePreview || preview}
              />
            ) : (
              <CustomFileSmallIcon
                type={file.extension}
                onPlayClick={() => {}}
              />
            )}
          </div>
        </div>
        <div className={cn('file__text', style.textWrapper)}>
          <span className={style.smallRowText}>{file?.name}</span>
          <div className={style.iconsWrapper}>
            {file?.is_clientside_encrypted && (
              <div className={style.encryptIcon}>
                <EncryptedIcon color={'var(--iconColor)'} />
              </div>
            )}
            {color && (
              <div
                className={style.colorWrapper}
                style={{ backgroundColor: color.hex }}
              ></div>
            )}
          </div>
        </div>

        <div className="file__created">
          {created.format('MMM DD, YYYY, H:mma')}
        </div>
        {!isMobile ? (
          <div className="file__shared">
            {file?.shares?.length
              ? `${file.shares.length} ${ownerT('common.people')}`
              : '-'}
          </div>
        ) : (
          <div className="file__shared">
            {file.shares.length} <SharedUserIcon />
          </div>
        )}
        <div className="file__secured">
          {file?.securities?.length > 0
            ? file?.securities
                .map((security) => security.keyword.toUpperCase())
                .join(', ')
            : 'NO'}
        </div>

        {showType && (
          <div className="file__type text--10">
            {ownerT('rightFileMenu.meta.file')}
          </div>
        )}
        <div className="file__size">{fileSize}</div>
      </section>
    );
  };
  const renderGeneral = () => {
    const imageHasPreview =
      !file?.securities?.length &&
      (imageMediaTypesPreview.includes(file.mime) ||
        imageFileExtensions.includes(`.${file.extension}`) ||
        docMediaTypesPreview.includes(file.mime) ||
        videoMediaExtentionPreview.includes(file?.extension?.toLowerCase()));
    const color = file?.color?.[file.color.length - 1];
    const videoHasPreview = videoMediaExtentionPreview.includes(
      file?.extension?.toLowerCase()
    );

    const rowFileClasses = cn({
      file: true,
      file_scaled: isDragged,
      file_square: viewType === 'square',
      'file_square-preview': viewType === 'square' && imageHasPreview,
      file_row: viewType === 'row',
      'file_row-preview': viewType === 'row' && imageHasPreview,
      showed: showFile,
      animated: true,
      fadeIn: true,
      disabled: disabled,
      active: isSelected,
      'is-edit': isEdit,
      'selected-entity': isSelectedEntity,
      'user-popup-position-container': true,
    });

    const content = (
      <section
        role="button"
        ref={forwardedRef}
        tabIndex={0}
        className={rowFileClasses}
        data-test={`file-comtainer_content_section[${file.slug}]`}
      >
        <div className="folder_square_dots">
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
          <div className="folder_square_dots__item" />
        </div>
        <div
          className={cn(
            'file__container',
            imageHasPreview && (filePreview || preview) && 'file__container-preview'
          )}
        >
          {filePreview || preview ? (
            <>
              <img
                key={file.id}
                className="file__image"
                alt={file.name}
                loading="eager"
                src={filePreview || preview}
              />
              {videoHasPreview && (
                <>
                  <PlayIcon
                    className={style.videoPlayIcon}
                    onClick={() => {}}
                    onMouseEnter={openHint}
                    onMouseLeave={closeHint}
                  />
                  {showHint && <CustomIconHint position={'top'} />}
                </>
              )}
            </>
          ) : (
            renderIcon(color)
          )}
        </div>
        {renderInfo()}
        <div className="user-popup-portal" ref={fileItemRef} />
      </section>
    );

    return viewType === 'square' ? (
      <div className="square__item">{content}</div>
    ) : (
      content
    );
  };

  const content = viewType === 'small-row' ? renderSmallRow() : renderGeneral();

  return <>{content}</>;
};

FileContainer.propTypes = {
  file: PropTypes.object,
  options: PropTypes.array.isRequired,
  showSharing: PropTypes.bool,
  showSize: PropTypes.bool,
  connectDragSource: PropTypes.func,
  isDragged: PropTypes.bool.isRequired,
  isDocument: PropTypes.bool,
  canDrag: PropTypes.bool,
  isEdit: PropTypes.bool,
  onDragBegin: PropTypes.func,
  onDragEnd: PropTypes.func,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  canSelect: PropTypes.bool,
  hideActions: PropTypes.bool,
  disableClick: PropTypes.bool,
  disableDoubleClick: PropTypes.bool,
  doAfterEdit: PropTypes.func,
  fileFolderActionHandlerEffect: PropTypes.func,
  selectedFilesAddEffect: PropTypes.func,
  selectedFilesRemoveEffect: PropTypes.func,
  onCheckChange: PropTypes.func,
  trash: PropTypes.bool,
  optionHandler: PropTypes.func,
};

FileContainer.defaultProps = {
  isEdit: false,
  canDrag: false,
  showSharing: false,
  isDocument: false,
  showSize: false,
  disabled: false,
  canSelect: true,
  showType: false,
  hideActions: false,
  disableClick: false,
  disableDoubleClick: false,
  doAfterEdit: () => {},
  fileFolderActionHandlerEffect: () => {},
  selectedFilesAddEffect: () => {},
  selectedFilesRemoveEffect: () => {},
  optionHandler: () => {},
  onCheckChange: () => {},
};

function isEqual(prev, next) {
  return (
    prev.selectedEntity.entity?.slug === next.selectedEntity.entity?.slug &&
    prev.viewType === next.viewType &&
    prev.isSelected === next.isSelected &&
    prev.isEdit === next.isEdit &&
    prev.file?.is_clientside_encrypted === next.file?.is_clientside_encrypted &&
    prev.file?.thumbnail === next.file?.thumbnail &&
    prev.file?.entry_clientside_key?.tag ===
      next.file?.entry_clientside_key?.tag &&
    prev.file?.entry_clientside_key?.iv ===
      next.file?.entry_clientside_key?.iv &&
    prev.file.color?.[prev.file.color.length - 1] ===
      next.file.color?.[next.file.color.length - 1] &&
    prev.file?.entry_groups === next.file?.entry_groups &&
    prev.uploadingFiles === next.uploadingFiles
  );
}

export default React.memo(FileContainer, isEqual);
