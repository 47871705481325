// Refactoring №3
import React, { useState, useEffect } from 'react';
import printJS from 'print-js';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'ramda';

import * as actions from 'store/home/actions/file/download-file.action';
import { getPublicSharedFileEffect } from 'store/file/effects';
import { setErrors } from 'store/errors/actions/index';
import { store } from 'store/root-store';
import { STORAGE_KEY } from 'store/context/theme';
import { getPublicSharedFileContent } from 'store/home/effects/file/get-file-content';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';
import { loginUsernameEffect } from 'store/auth/effects';
import { MainContextWrapper } from 'store/context';

import { isMobile } from 'utils/mobile';
import getToken from 'utils/auth/get-token';
import { validationRegExp } from 'utils/validation/regexp';
import { enterKeyPress } from 'utils/actions/enter-key-press';
import { validateToken } from 'utils/auth/check-token';
import { isShareMobileApp } from 'utils/url/is-page';

import { handleVaultModal } from 'features/modals/modal-slice';
import imageSupportedFormats, {
  imageMediaTypes,
} from 'config/image-file-extensions';
import actionsOptions from 'config/actions-options';
import { docMediaTypes } from 'config/docs-file-extensions';

import Lock from '../../folder/Lock';
import Preloader from 'components/shared/Preloader';
import FileView from '../../main/FileView/file-view';
import Header from '../../header/header-container/no-auth-header';
import { FileContainer } from '../../shared/file-container';
import { SharingEntityMobileModal } from 'containers/shared-by-link/SharingEntityMobileModal';
import removeToken from 'utils/auth/remove-token';

import {
  SignInModal,
  validations,
  minCharsLength,
  DEFAULT_ERROR_MESSAGES,
} from 'containers/auth/AcceptInvite/sign-in-modal';
import { SharedLayout } from 'containers/auth/components/SharedLayout';

import style from './style.module.scss';

const SharedFile = ({ fileId }) => {
  const { t } = useTranslation('account');
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const [file, setFile] = useState(null);
  const [viewType, setViewType] = useState('square');
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(true);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [currentErrors, setCurrentErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGES);
  const dispatch = useDispatch();
  const [fieldData, setFieldData] = useState({
    email: '',
    password: '',
    name: '',
  });
  const isAuthorized = !!getToken();
  const isTelegram = searchParams.get('is_telegram') === 'true';

  const closeMobileModal = () => {
    setIsOpenMobileModal(false);
  };

  const canBePrinted =
    isSelected &&
    (imageSupportedFormats.includes('.' + file?.extension) ||
      file?.extension === 'pdf' ||
      file?.extension === 'html');

  useEffect(() => {
    const theme = localStorage.getItem(STORAGE_KEY) || '';
    document.documentElement.setAttribute(STORAGE_KEY, theme);
    return () => {
      document.documentElement.setAttribute(STORAGE_KEY, '');
    };
  }, []);

  useEffect(() => {
    if (isShareMobileApp()) {
      document.title = 'Ghost Drive';
    }
  }, [])

  useEffect(() => {
    const fileSignal = axios.CancelToken.source();
    if (fileId) {
      getPublicSharedFileEffect(fileId)
        .then((data) => {
          if (data?.data && !data?.data?.length) {
            if (isAuthorized) {
              onAccessDeny();
            } else {
              renderSignInModal();
            }
            return;
          } else if (data.extension === 'vault') {
            store.dispatch(handleVaultModal({ isOpen: true, slug: fileId }));
          }
          const file = data?.entry ? data?.entry : data;
          setFile(file);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            setShowSignInModal(true);
            removeToken();
            // authTokenRefresh()
            //   .catch(() => {
            //     onAccessDeny();
            //   });
          } else if (response.status === 403) {
            setIsAccessDenied(true);
          }
          // if (isAuthorized) {
          // } else {
          //   renderSignInModal();
          // }
        });
    }

    return () => {
      fileSignal.cancel('getPublicSharedFileContent is being canceled');
    };
  }, [fileId]);

  const renderSignInModal = () => {
    setShowSignInModal(true);
  };
  const onAccessDeny = () => {
    if (isAuthorized) {
      setIsAccessDenied(true);
      store.dispatch(setErrors({ data: 403, message: 'Access deny' }));
    } else {
      renderSignInModal();
    }
  };
  const validatePassword = (value) => {
    const validRules = {};
    validations.forEach((validation) => {
      if (validation === 'hasMinLength') {
        validRules[validation] = value.length >= minCharsLength;
      } else {
        validRules[validation] = validationRegExp[validation].test(value);
      }
    });
    return validRules;
  };
  const onInputChange = (e) => {
    let isValid = true;
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      if (name === 'email') {
        isValid = validationRegExp.email.test(value);
      } else if (name === 'password') {
        const newValid = validatePassword(value);
        isValid = !!isEmpty(
          Object.values(newValid).filter((item) => item === false)
        );
      } else {
        isValid = !!value;
      }

      setFieldData({
        ...fieldData,
        [name]: value,
      });
    }

    setCurrentErrors({
      ...currentErrors,
      [name]: !isValid,
    });
  };

  const handleKeyDown = (e) => {
    if (enterKeyPress(e)) {
      onSignInHandler();
    }
  };

  const onSignInHandler = () => {
    !submitted && setSubmitted(true);
    if (
      fieldData.email &&
      !currentErrors.email &&
      fieldData.password &&
      !currentErrors.password
    ) {
      loginUsernameEffect(fieldData.email, fieldData.password)
        .then(() => {
          if (validateToken()) {
            location.reload();
          }
        })
        .catch(catchCredentialError);
    }
  };

  const catchCredentialError = ({ errors, message }) => {
    !submitted && setSubmitted(true);

    if (errors) {
      const fieldErrors = {};
      const fieldErrorMsg = {};
      Object.keys(errors?.children).forEach((fieldName) => {
        fieldErrors[fieldName] = !!errors?.children[fieldName].errors;
        fieldErrorMsg[fieldName] =
          errors?.children[fieldName].errors?.join(', ') || '';
      });
      setErrors(fieldErrors);
      setErrorMessages(fieldErrorMsg);
    } else {
      setErrors({
        email: true,
        password: true,
      });
      setErrorMessages({
        email: message ? message : '',
        password: message ? message : '',
      });
    }
  };

  if (!file && !isAccessDenied && !showSignInModal)
    return (
      <>
        <Preloader />
      </>
    );

  const downloadFile = () => {
    dispatch(
      actions.startDownload(getTotalDownloadFileSize([file]), file.slug, [file])
    );
    dispatch(fileFolderActionHandlerEffect(file, actionsOptions.download));
  };

  const onCheckChange = (selected, file) => {
    setIsSelected(selected);
    setFile(file);
  };

  const printFile = () => {
    if (!canBePrinted) return;
    if (canBePrinted) {
      let fileType = 'pdf';
      if (imageMediaTypes.includes(file?.mime)) {
        fileType = 'image';
      } else if (docMediaTypes.includes(file?.mime)) {
        fileType = 'html';
      }
      const fileSignal = axios.CancelToken.source();
      getPublicSharedFileContent(
        file.slug,
        fileSignal.token,
        true,
        file.gateway
      ).then((data) => {
        if (data) {
          printJS(data, fileType);
        }
      });
    }
  };

  const fileClickDoubleClickHandler = (file, option) => {
    const { viewFile, download, openPassword } = actionsOptions;
    if (option.type === viewFile.type) {
      if (file.extension === 'pages') {
        history.push(`/file/shared/editor/${file.slug}`);
      } else if (file.extension === 'vault') {
        store.dispatch(
          fileFolderActionHandlerEffect(file, openPassword, {
            skipGetData: true,
          })
        );
      } else {
        setModalIsOpen(true);
      }
    } else if (option.type === download.type) {
      downloadFile(true);
    }
  };

  return (
    <>
      {!showSignInModal && (
        <section className={style.sharedByLink}>
          <Header
            fileViewType={viewType}
            setFileViewTypeEffect={setViewType}
            files={[file]}
            downloadEntities={downloadFile}
            printEntities={printFile}
            canBePrinted={canBePrinted}
            selectedFile={file}
            isForbidden={isAccessDenied}
            hideBreadcrumb
          />
          <main>
            <div className={style.sharedByLink__main}>
              {isAccessDenied ? (
                <div className={style.sharedByLink__main__accessDenied}>
                  <div className={style.sharedByLink__main__accessDenied__lock}>
                    <Lock />
                  </div>
                  <span
                    className={style.sharedByLink__main__accessDenied__boldText}
                  >
                    {t('security.accessDenied')}
                  </span>
                  <span
                    className={style.sharedByLink__main__accessDenied__text}
                  >
                    {t('security.askUserToInviteDirectly')}
                  </span>
                </div>
              ) : (
                <FileContainer
                  file={file}
                  viewType={viewType}
                  options={[]}
                  canDrag={false}
                  disabled={false}
                  isSelected={isSelected}
                  isDragged={false}
                  hideActions
                  fileFolderActionHandlerEffect={fileClickDoubleClickHandler}
                  selectedEntity={{ entity: file }}
                  isPublic
                  onCheckChange={onCheckChange}
                />
              )}
              <FileView
                isOpen={modalIsOpen}
                entity={file || {}}
                onRequestClose={() => setModalIsOpen(false)}
                isPublic
              />
            </div>
          </main>
        </section>
      )}
      {isOpenMobileModal && isMobile && !isAccessDenied && !isTelegram && (
        <SharingEntityMobileModal
          closeMobileModal={closeMobileModal}
          name={file?.name}
          entityType="file"
          slug={fileId}
        />
      )}
      {showSignInModal && (
        <MainContextWrapper>
          <SharedLayout type="joinWorkspace">
            <SignInModal
              onSignInHandler={onSignInHandler}
              fieldData={fieldData}
              onInputChange={onInputChange}
              handleKeyDown={handleKeyDown}
              currentErrors={currentErrors}
              errorMessages={errorMessages}
            />
          </SharedLayout>
        </MainContextWrapper>
      )}
    </>
  );
};

export default SharedFile;
