import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { useDispatch } from 'react-redux';
import { isEmpty } from "lodash";
import moment from 'moment';

import TemplateForm from "containers/main/NeyraDriveContainer/components/ContentDrive/Template";
import { handleCreateDocumentModal, handleAdditionalData } from 'features/modals/modal-slice';
import { createDocumentEffect } from 'store/document/effects';
import Button, { BUTTON_TYPE } from "../Template/Button";
import Input from "../Template/Input";
import { IFile } from 'interfaces/file-folder';
import getFolderId from 'utils/files-folders/get-folder-id';

import styles from './styles.module.scss';
import { cutExtention } from "utils/string";

type IProps = {
  entity: IFile,
  onClose: () => void,
  dispatch: any,
}

const CreateRename = ({ onClose, entity, dispatch }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const [value, setValue] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (entity) {
      setValue(cutExtention(entity.name))
    }
  }, [entity])

  const onChange = ({ target: { value : nextValue } }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(nextValue);
  }

  const placeholder = useMemo(() => {
    if (isEmpty(entity))
      return t('main.addPage.enterTitle');
    return ''
  }, [entity]);

  const onCreateDocument = (name: string) => {
    const folderId = getFolderId(history.location);
    createDocumentEffect(name, folderId).then((data) => {
      const newDocument = { ...data, created_at: moment().unix() };
      if (newDocument.slug) {
        history.push(
          `/memo/${newDocument.slug}?filename=${newDocument?.name}&filesize=${newDocument?.size}`
        );
      }
      dispatch(handleCreateDocumentModal(false));
    });
  }

  const handleAction = () => {
    if (!value.trim())
      return
      onCreateDocument(value)
  }

  return (
    <div className={styles.createContainer}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className={styles.footer}>
        <Button
          onClick={onClose}
          type={BUTTON_TYPE.cancel}
        >
          {t('main.createFolder.cancel')}
        </Button>
        <Button
          onClick={handleAction}
        >
          {t('main.createFolder.create')}
        </Button>
      </div>
    </div>
  )
}

const CreateRenameModal = ({ entity, ...rest }: any) => {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();

  const onCloseModal= () => {
    dispatch(handleCreateDocumentModal(false));
    dispatch(handleAdditionalData(null));
  }

  return (
    <TemplateForm
      onClose={onCloseModal}
      title={t('createMemo')}
    >
      <CreateRename
        {...rest}
        entity={entity}
        dispatch={dispatch}
        onClose={onCloseModal}
      />
    </TemplateForm>
  )
}

export default CreateRenameModal;