//Refactoring №3
import axios from 'axios';

import {
  fileRemoveFailure,
  fileRemoveSuccess,
} from '../../actions/file/remove-file-from-trash.actions';
import { selectedFilesRemove } from '../../actions/selected-files-folders.actions';
import { getFilesRemove } from '../../actions';
import { getOneTimeTokenForDeleting } from 'store/home/effects';

export const entityClearAllEffect = async (
  dispatch: (cb: any) => void,
  files: any
) => {
  const fileSlugs = files.map((file: any) => file.slug)
  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
    },
  } = await getOneTimeTokenForDeleting(fileSlugs);

  const url = `${gateway.url}/trash/all`;

  return axios
    .create({
      headers: {
        'one-time-token': oneTimeToken,
        'X-Delete-OTT-JWT': jwt_ott,
      },
    })
    .delete(url, { data: fileSlugs })
    .then(() => {
      dispatch(fileRemoveSuccess());
      dispatch(selectedFilesRemove(files));
      dispatch(getFilesRemove(files));
    })
    .catch(() => {
      dispatch(fileRemoveFailure());
    });
};
