/* eslint-disable no-debugger */
// libs
import axios from 'axios';
import useAxios from 'axios-hooks';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

// utils
import authRequest from 'utils/request/auth-request';
import getToken from 'utils/auth/get-token';
import { updateFileNameEffect } from 'store/home/effects/file/file-update.effect';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';
import { downloadFileFromSP } from 'gdgateway-client/lib/es5/downloadFile/downloadFileFromSP';
import {
  getDownloadOTT,
  getOneTimeToken,
} from 'store/home/effects/files-upload/upload-file.effect';
import {
  API_FILE,
  API_PUB,
  API_REWARD_FIRST_TYPE,
  API_REWARD_CLAIM,
  API_REWARD_ACHIEVEMENT,
  API_CONVERSION_TOKEN,
} from 'constants/api-urls';

const previewUpdateDay = 1689920400;

export const filesGetEffect = async (fileId) => {
  return authRequest
    .get(process.env.REACT_APP_API_PATH + `/files/file/${fileId}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const filesGetNeyroEffect = async (fileId) => {
  return axios
    .create({
      headers: {
        'X-Token': getToken(),
      },
    })
    .get(`${process.env.REACT_APP_API_PATH}/get/demo/file/${fileId}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const fileGetPubContentEffect = async (fileId) => {
  const url = `${API_PUB}/content/${fileId}`;

  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const fileUpdateContentEffect = async (
  fileId,
  content,
  oneTimeTokenData
) => {
  const {
    data: { jwt_ott, user_token, gateway },
  } = await getOneTimeToken([
    { ...oneTimeTokenData, slug: fileId, isPublic: true },
  ]);
  const url = `${gateway.url}/pages/${fileId}`;
  const oneTimeToken = user_token[0].token;

  const name = oneTimeTokenData.filename.substring(
    0,
    oneTimeTokenData.filename.lastIndexOf('.')
  );

  return authRequest
    .put(
      url,
      { ...content, name: name },
      {
        headers: {
          'one-time-token': oneTimeToken,
          'X-Upload-OTT-JWT': jwt_ott[0],
          'X-extension': 'memo',
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const getPublicSharedFileEffect = async (fileId, page) => {
  const url = `${API_PUB}/entry/${fileId}${page ? `?page=${page}` : ''}`;
  const token = getToken();

  const headers = {};
  if (token) {
    headers['X-Token'] = token;
  }

  return axios
    .create({
      headers: {
        'X-Token': token,
      },
    })
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const useGetPublicSharedFileEffect = (fileId, page) => {
  const url = `${API_PUB}/entries/${fileId}${page ? `?page=${page}` : ''}`;
  const [{ data, loading, error }, refetch] = useAxios(url);

  return { data, loading, error, refetch };
};

export const updateNftMetadataEffect = (data) => {
  const url = `${process.env.REACT_APP_API_PATH}/nft/add/metadata`;

  return authRequest
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const reindexNftMetadataEffect = (slug, contract) => {
  const url = `${API_FILE}/${slug}/reindex/tokenization `;
  return authRequest
    .post(url, { contract })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const addRewards = (type) => {
  //type: 2 - First tokenization file/folder
  //type: 3 - Mint a first NFT access to your friend
  const url = `${API_REWARD_FIRST_TYPE}/${type}`;
  return authRequest
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response.message;
    });
};

export const claimRewards = () => {
  const url = `${API_REWARD_CLAIM}`;
  return authRequest
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response.message;
    });
};

export const getReceivedRewardsAchievement = () => {
  const url = `${API_REWARD_ACHIEVEMENT}`;
  return authRequest
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response.message;
    });
};

export const setVaultFileContent = async (
  vault,
  slug,
  chnageFile,
  dispatch,
  folderSlug
) => {
  const fileName = `${vault.passwordName}.vault`;

  const blob = new Blob([new TextEncoder().encode(JSON.stringify(vault))], {
    type: 'application/json',
  });
  let file = new File([blob], fileName, {
    type: 'application/json',
  });

  const formData = new FormData();
  folderSlug && formData.append('folder', folderSlug);
  formData.append('file', file);
  const {
    data: { jwt_ott, user_token, gateway },
  } = await getOneTimeToken([
    { filename: file.name, filesize: file.size, isPublic: true },
  ]);
  const url = `${gateway.url}/vault${slug ? `?slug=${slug}` : ''}`;
  const token = user_token[0].token;
  return axios
    .create({
      'Content-Type': 'multipart/form-data',
      headers: { 'one-time-token': token, 'X-Upload-OTT-JWT': jwt_ott[0] },
    })
    .post(url, formData)
    .then((response) => {
      if (slug && chnageFile.name !== fileName) {
        updateFileNameEffect(slug, fileName).then((updatedFile) => {
          dispatch(
            getFilesUpdate({
              entity: updatedFile,
              updatedPropsList: ['name', 'hash', 'updated_at'],
            })
          );
        });
      }

      return response.data;
    })
    .catch((response) => {
      throw response.message;
    });
};

export const setLinkFileContent = async (
  vault,
  slug,
  chnageFile,
  dispatch,
  folderSlug
) => {
  const fileName = `${vault.name}.link`;
  const blob = new Blob([new TextEncoder().encode(JSON.stringify(vault))], {
    type: 'application/json',
  });
  let file = new File([blob], fileName, {
    type: 'application/json',
  });

  const formData = new FormData();
  folderSlug && formData.append('folder', folderSlug);
  formData.append('file', file);
  const {
    data: { jwt_ott, user_token, gateway },
  } = await getOneTimeToken([
    { filename: file.name, filesize: file.size, isPublic: true },
  ]);
  const url = `${gateway.url}/vault${slug ? `?slug=${slug}` : ''}`;
  const token = user_token[0].token;
  return axios
    .create({
      'Content-Type': 'multipart/form-data',
      headers: {
        'one-time-token': token,
        'X-Upload-OTT-JWT': jwt_ott[0],
        'X-extension': 'link',
      },
    })
    .post(url, formData)
    .then((response) => {
      if (slug && chnageFile.name !== fileName) {
        updateFileNameEffect(slug, fileName).then((updatedFile) => {
          dispatch(
            getFilesUpdate({
              entity: updatedFile,
              updatedPropsList: ['name', 'hash', 'updated_at'],
            })
          );
        });
      }

      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const getAvailableConvertFormats = async (extension) => {
  const url = `${process.env.REACT_APP_FILE_CONVERSION}?filter[input_format]=${extension}`;
  const formats = await fetch(url)
    .then((res) => res.json())
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
  return formats;
};

export const getConversionToken = async (slug) => {
  const url = `${API_CONVERSION_TOKEN}`;
  return await fetch(url, {
    method: 'POST',
    headers: {
      'x-token': getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ slug }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const convertFileEffect = async (slug, format) => {
  try {
    const token = await getConversionToken(slug);
    const url = `${token.gateway}/pub/convert/${slug}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'one-time-token': token.user_token.token,
        'x-format': format,
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.errors.message);
    }

    return result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const convertAiImageEffect = async (image) => {
  try {
    const token = await getConversionToken();
    const url = `${process.env.REACT_APP_API_PATH}/convert/image`;
    const formData = new FormData();
    formData.append('file', image);
    formData.append('format', 'webp');

    const response = await axios.post(url, formData, {
      headers: {
        'one-time-token': token.user_token.token,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getFileImagePreview = (file, callback) => {
  if (!file?.thumbnail && file?.preview_small) {
    getDownloadOTT([{ slug: file.slug }]).then(async (res) => {
      if (!file.is_on_storage_provider) {
        authRequest
          .get(`${res.data.gateway.url}/preview/${file.slug}`, null, {
            options: {
              responseType: 'blob',
            },
            headers: {
              'one-time-token': res.data.user_tokens.token,
              'X-Download-OTT-JWT': res.data.jwt_ott,
            },
          })
          .then(async (res) => {
            const text = await res.data.text();
            if (text.startsWith('data:image')) {
              if (file.created_at > previewUpdateDay) {
                callback(text);
              }
            } else {
              if (file.created_at > previewUpdateDay) {
                const urlCreator = window.URL || window.webkitURL;
                callback(urlCreator.createObjectURL(res.data));
              }
            }
          });
      } else {
        if (!file.preview_large && !file.preview_small) {
          callback(null);
          return;
        }
        const fileBlob = await downloadFileFromSP({
          carReader: CarReader,
          url: `${file.storage_provider.url}/${
            file.preview_large ?? file.preview_small
          }`,
          isEncrypted: false,
          uploadChunkSize: 0,
          key: undefined,
          iv: undefined,
          file,
          level: 'root',
        });

        const realBlob = new Blob([fileBlob]);
        const text = await realBlob?.text();
        if (text && text.startsWith('data:image')) {
          if (file.created_at > previewUpdateDay) {
            callback(text);
          }
        } else {
          if (file.created_at > previewUpdateDay) {
            const urlCreator = window.URL || window.webkitURL;
            callback(urlCreator.createObjectURL(res.data));
          }
        }
      }
    });
  }
};
