import { uploadMultiFileEffectRedux } from '../home/effects';
import { store } from 'store/root-store';
import { createPageFile } from 'utils/file/createFile';

export const createDocumentEffect = async (name, folder = null) => {
  const file = createPageFile({ name, extension: 'memo', folder });
  file.folderId = folder;
  file.mime = file.type;
  file.needEncryption = false;
  const timestamp = Date.now();
  file.uploadId = `${file.name}_${file.size}_${timestamp}`;

  return new Promise((resolve, reject) => {
    store.dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files: [file],
        folderId: folder,
        setErrors: false,
        isGeneratedByAi: false,
        resultCb: (response) => {
          try {
            const {
              data: { data },
            } = response;
            resolve(data);
          } catch (error) {
            reject(error);
          }
        },
      })
    );
  });
};
