/* eslint-disable no-debugger */
//Refactoring №3
import { isArray } from 'lodash';
import * as actions from '../actions/files/get-files.actions';

import getErrorData from 'utils/request/get-error-data';
import getData from 'utils/request/get-data';
import removeBy from 'utils/array/remove-by';
import removeIfEqual from 'utils/array/remove-if-equal';
import {
  updateObjInArray,
  updateObjInArrayByUploadSlug,
} from 'utils/array/update-obj-in-array';

export const initialState = {
  entities: [],
  count: 0,
  currentPage: 1,
  getFilesError: null,
  firstLoaded: false,
  reload: false,
  wasLoaded: false,
  status: false,
  sortData: {},
  filterDate: {},
  securities: {},
};

export default (state = initialState, action) => {
  let data, file, indexPreloadFile;
  switch (action.type) {
    case actions.GET_FILES:
      return {
        ...state,
        getFilesError: null,
        status: true,
      };

    case actions.GET_FILES_ADD:
      if (getData(action).type === 2) {
        const folders = state.entities.filter((entity) => entity.type === 2);
        const files = state.entities.filter((entity) => entity.type === 1);
        return {
          ...state,
          count: state.count + (isArray(file) ? file.length : 1),
          wasLoaded: true,
          entities: [...folders, getData(action), ...files],
        };
      }
      return {
        ...state,
        count: state.count + (isArray(file) ? file.length : 1),
        wasLoaded: true,
        entities: [getData(action), ...state.entities],
      };

    case actions.GET_FILES_RELOAD:
      return {
        ...state,
        reload: true,
      };

    case actions.GET_FILES_SUCCESS:
      if (action.clear) {
        return {
          ...state,
          entities: [...getData(action).data],
          count: getData(action).count,
          members: getData(action).members,
          wasLoaded: true,
          firstLoaded: true,
          reload: false,
          status: false,
        };
      }
      return {
        ...state,
        entities: [...state.entities, ...getData(action).data],
        count: getData(action).count,
        members: getData(action).members,
        wasLoaded: true,
        firstLoaded: true,
        reload: false,
        status: false,
      };

    case actions.GET_FILES_UPDATE_LIST: {
      const folders = action.payload.filter((entity) => entity.type === 2);
      const files = action.payload.filter((entity) => entity.type === 1);
      return {
        ...state,
        entities: [...folders, ...files],
      };
    }

    case actions.UPDATE_FILE_PROP: {
      const newList = state.entities.map((el) =>
        action.payload.slug === el.slug
          ? { ...el, [action.payload.key]: action.payload.value }
          : el
      );
      return {
        ...state,
        entities: newList,
      };
    }

    case actions.GET_FILES_CLEAR:
      return {
        ...state,
        entities: [],
        wasLoaded: false,
        status: false,
        reload: false,
        count: 0,
      };

    case actions.GET_FILES_UPDATE:
      data = action.payload;
      return {
        ...state,
        entities: updateObjInArray(
          data.entity,
          state.entities,
          'slug',
          data.updatedPropsList
        ),
      };

    case actions.GET_FILES_ADD_PRELOAD:
      data = action.payload
      indexPreloadFile = state.entities.findIndex(
        (item) => item.slug === action.payload.slug
      );
      if (indexPreloadFile !== -1) {
        return { ...state };
      }
      if (data.type === 2) {
        const folders = state.entities.filter((entity) => entity.type === 2);
        const files = state.entities.filter((entity) => entity.type === 1);
        return {
          ...state,
          count: state.count + (isArray(file) ? file.length : 1),
          wasLoaded: true,
          entities: [...folders, data, ...files],
        };
      }
      return {
        ...state,
        count: state.count + (isArray(file) ? file.length : 1),
        wasLoaded: true,
        entities: [data, ...state.entities],
      };

    case actions.GET_FILES_UPDATE_PRELOAD:
      data = action.payload;
      return {
        ...state,
        entities: updateObjInArrayByUploadSlug(
          data.entity,
          state.entities,
          data.prevSlug
        ),
      };

    case actions.GET_FILES_REMOVE:
      file = getData(action);
      return {
        ...state,
        count: state.count - (isArray(file) ? file.length : 1),
        entities: isArray(file)
          ? removeIfEqual(state.entities, file, 'slug')
          : removeBy(file, state.entities, 'slug'),
      };

    case actions.GET_FILES_FAILURE:
      return {
        ...state,
        getFilesError: getErrorData(action),
        status: false,
        reload: false,
      };

    case actions.GET_FILES_SET_SECURITIES:
      return {
        ...state,
        securities: action.payload,
      };

    case actions.GET_FILES_CLEAR_SECURITIES:
      return {
        ...state,
        securities: '',
      };

    case actions.GET_SORT_UPDATE:
      return {
        ...state,
        getFilesError: null,
        status: true,
        sortData: action.payload,
      };

    case actions.GET_FILES_UPDATE_FAVORITE:
      return {
        ...state,
        entities: state.entities.map((item) => (item.slug === action.payload.slug ? action.payload : item))
      };

    case actions.UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};
