/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { downloadFile } from 'gdgateway-client/lib/es5';
import { CarReader } from '@ipld/car';
import CN from 'classnames';
import printJS from 'print-js';

import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';
import { getFileCids } from 'store/home/effects/file/get-file-cid';
import {
  handlePreviewModal,
  handleMoveModal,
  handleCreateFolderModal,
} from 'features/modals/modal-slice';
import * as actions from 'store/home/actions/file/download-file.action';

import PreviewContent from './components/PreviewContent';

import GhostLoader from 'components/GhostLoader';
import { getEncryptedFileKey } from 'containers/main/Main/components/Sharing/utils/get-key';
import { ReactComponent as DownloadIcon } from 'containers/main/FileView/assets/download.svg';
import { ReactComponent as PrintIcon } from 'containers/main/FileView/assets/print.svg';
import { ReactComponent as MoveIcon } from 'containers/main/FileView/assets/move.svg';
import { ReactComponent as RenameIcon } from 'containers/main/FileView/assets/rename.svg';
import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';

import { IFile } from 'interfaces/file-folder';

import { docMediaTypes, pdfMediaTypes } from 'config/docs-file-extensions';
import { ONE_MB, MAX_PREVIEW_SIZE } from 'config/file-action-types';
import { downloadFileData } from 'utils/file/library-callbacks';
import { imageMediaTypes } from 'config/image-file-extensions';
import { getDecryptedKey } from 'utils/file/getDecryptedKey';
import { getKeyFromLS } from 'utils/file/getKeyFromLS';
import actionsOptions, { actionsType } from 'config/actions-options';
import { getPreviewFileType } from './utils';

import styles from './styles.module.scss';

type IProps = {
  entity: IFile;
};

const filesWithOwnPreview = [
  'audio',
  'video',
  'pdf',
  'document',
  'xlsx',
  'txt',
];

const Preview = ({ entity }: IProps) => {
  const dispatch = useDispatch();
  const previewRef = useRef(null);
  const previewContentRef = useRef(null);
  const [shadowBoxState, setShadowBoxState] = useState({
    top: false,
    bottom: false,
  });
  const { t: accountT } = useTranslation('account');
  const [entityContent, setEntityContent] = useState('');
  const [loading, setLoading] = useState(false);
  const canDownload = entity?.is_downloaded;

  useEffect(() => {
    if (entity?.slug) {
      const canPreview = getPreviewFileType(entity, '   ');
      setLoading(true);
      if (canPreview && canPreview !== 'encrypt') {
        if (filesWithOwnPreview.includes(canPreview)) {
          setLoading(false);
        } else {
          getContent();
        }
      } else {
        setLoading(false);
      }
    }
  }, [entity]);

  useEffect(() => {
    const previewType = getPreviewFileType(entity, ' ');
    if (previewRef.current && !loading && previewType === 'pdf') {
      setTimeout(() => {
        const data = document.getElementsByClassName(
          'file-content-pdf-view'
        )[0];
        data?.addEventListener('scroll', handleScroll);
      }, 300);
    } else if (entityContent && !loading && previewType === 'img') {
      setTimeout(() => {
        previewContentRef.current.style.position = 'absolute';
        const image = previewContentRef.current.getElementsByTagName('img')[0];
        if (previewContentRef.current.offsetHeight - 100 < image.offsetHeight) {
          setShadowBoxState({ top: true, bottom: false });
        }
      }, 300);
    }
  }, [previewRef, entityContent, loading]);

  // The scroll listener
  const handleScroll = useCallback(({ target }: any) => {
    const clientRect = target.firstChild.getBoundingClientRect();
    const nextState = { top: false, bottom: false };

    if (clientRect.top < -50) {
      nextState.top = true;
    }
    if (clientRect.height + clientRect.top > 1300) {
      nextState.bottom = true;
    }

    setShadowBoxState(nextState);
  }, []);

  const getContent = async () => {
    try {
      await sendFileViewStatistic(entity.slug);
      const cidData = await getFileCids({ slug: entity.slug });
      const {
        data: {
          jwt_ott,
          user_tokens: { token: oneTimeToken },
          gateway,
          upload_chunk_size,
        },
      } = await getDownloadOTT([{ slug: entity.slug }], actionsType.VIEWED);

      await sendFileViewStatistic(entity?.slug);
      const blob = await downloadFile({
        file: entity,
        oneTimeToken,
        jwtOneTimeToken: jwt_ott,
        endpoint: gateway.url,
        isEncrypted: false,
        carReader: CarReader,
        uploadChunkSize:
          upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
        cidData,
      });
      if (blob) {
        const realBlob = new Blob([blob]);

        const url = URL.createObjectURL(realBlob);
        if (entity.extension === 'svg') {
          const text = await realBlob.text();
          setEntityContent(text);
          setLoading(false);
          return;
        }
        setEntityContent(url);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.warn(error);
    }
  };

  const onRealDownload = () => {
    if (canDownload) {
      dispatch(
        actions.startDownload(getTotalDownloadFileSize([entity]), entity.slug, [
          entity,
        ])
      );
      dispatch(fileFolderActionHandlerEffect(entity, actionsOptions.download));
    }
  };

  const printFile = async () => {
    let url;
    if (
      typeof entityContent === 'string' &&
      entityContent.startsWith('blob:')
    ) {
      url = entityContent;
    } else {
      const { handlers, callbacks } = downloadFileData;
      const size = Number((entity.size / ONE_MB).toFixed(1));
      let cidData;

      const callback = ({ type, params }: any) => {
        if (handlers.includes(type)) {
          callbacks[type]({ ...params, dispatch });
        } else {
          console.error(`Handler "${type}" isn't provided`);
        }
      };
      const {
        data: {
          jwt_ott,
          user_tokens: { token: oneTimeToken },
          gateway,
          upload_chunk_size,
        },
      } = await getDownloadOTT([{ slug: entity.slug }]);
      if (entity?.is_on_storage_provider && size >= MAX_PREVIEW_SIZE) {
        cidData = await getFileCids({ slug: entity.slug });
      }
      if (entity.is_clientside_encrypted) {
        if (!isPublic) {
          const decryptedKeyFromLS = getKeyFromLS({ slug: entity.slug });
          const key = decryptedKeyFromLS
            ? decryptedKeyFromLS
            : await getEncryptedFileKey(entity, dispatch);
          if (key) {
            const decryptedKey = decryptedKeyFromLS
              ? key
              : await getDecryptedKey({ key });

            const blob = await downloadFile({
              file: entity,
              oneTimeToken,
              jwtOneTimeToken: jwt_ott,
              endpoint: gateway.url,
              isEncrypted: true,
              key: decryptedKey,
              callback,
              handlers,
              carReader: CarReader,
              uploadChunkSize:
                upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
              cidData,
            });
            const realBlob = new Blob([blob]);
            url = URL.createObjectURL(realBlob);
          }
        }
      } else {
        if (entity?.mime === 'text/plain') {
          printJS({ printable: 'preview-container', type: 'html' });
          return;
        }
        const controller = new AbortController();
        cidData = await getFileCids({ slug: entity.slug });
        const blob = await downloadFile({
          file: entity,
          oneTimeToken,
          jwtOneTimeToken: jwt_ott,
          endpoint: gateway.url,
          isEncrypted: false,
          callback,
          handlers,
          signal: controller.signal,
          carReader: CarReader,
          uploadChunkSize:
            upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
          cidData,
        });
        const realBlob = new Blob([blob]);
        url = URL.createObjectURL(realBlob);
      }
    }
    let fileType = 'pdf';
    if (imageMediaTypes.includes(entity?.mime)) {
      fileType = 'image';
    } else if (entity?.mime === 'application/msword') {
      fileType = 'pdf';
    } else if (docMediaTypes.includes(entity?.mime)) {
      fileType = 'html';
    }
    printJS({ printable: url, type: fileType });
  };

  const onMoveFile = () => {
    dispatch(handlePreviewModal({ isOpen: false, entity }));
    dispatch(handleMoveModal({ isOpen: true, entity: [entity] }));
  };

  const onRenameFile = () => {
    dispatch(handlePreviewModal({ isOpen: false, entity }));
    dispatch(handleCreateFolderModal(true));
  };

  const onClose = () => {
    dispatch(handlePreviewModal({ isOpen: false, entity: null }));
  };

  return (
    <div ref={previewRef} className={styles.previewContainer}>
      <div
        className={CN(
          styles.topShadowBox,
          shadowBoxState.top && styles.shadowBoxDisplay
        )}
      />
      <div className={styles.previewHeader}>
        <h1 className={styles.fileName}>{entity.name}</h1>
        <div className={styles.actions}>
          <button className={styles.actionBtn} onClick={onRealDownload}>
            <DownloadIcon />
          </button>
          <button className={styles.actionBtn} onClick={printFile}>
            <PrintIcon />
          </button>
          <button className={styles.actionBtn} onClick={onMoveFile}>
            <MoveIcon />
          </button>
          <button className={styles.actionBtn} onClick={onRenameFile}>
            <RenameIcon />
          </button>
          <button className={styles.actionBtn} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
      <div ref={previewContentRef} className={styles.previewContent}>
        {loading ? (
          <div className={styles.loadingPreview}>
            <GhostLoader
              texts={[
                accountT('convertModal.loading'),
                accountT('convertModal.pleaseWait'),
              ]}
            />
          </div>
        ) : (
          <PreviewContent entityContent={entityContent} entity={entity} />
        )}
      </div>
      <div
        className={CN(
          styles.bottomShadowBox,
          shadowBoxState.bottom && styles.shadowBoxDisplay
        )}
      />
    </div>
  );
};

export default Preview;
