//Refactoring №4
import React, { useEffect } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
// import MetaMaskSDK from '@metamask/sdk';
import Web3 from 'web3';
import dotenv from 'dotenv';
import i18n from 'i18next';
import { locale } from 'moment';
import 'moment/locale/fil';
import 'moment/locale/id';
import 'moment/locale/zh-cn';

import HomeResolver from 'resolvers/home-resolver';
import GetInvite from 'features/getInvite/GetInvite';

import SignInWithEmail from 'containers/auth/SignInWithEmail';
// import SignIn from 'containers/auth/SignIn';
import NeyraRegistration from 'containers/auth/NeyraRegistration';
import NeyraSignIn from 'containers/auth/NeyraWelcome';
import NeyraAI from 'containers/auth/NeyraAI';
// import NeyraChat from 'containers/auth/NeyraChat';
import OrderRing from 'containers/auth/OrderRing';
import SignUp from 'containers/auth/SignUp';
import Ipfs from 'containers/ipfs';
import AuthToken from 'containers/auth/auth-token';
import AcceptInvite from 'containers/auth/AcceptInvite/accept-invite';
import NoWorkSpace from 'containers/auth/NoWorkspace';
import CreateWorkspace from 'containers/auth/create-workspace';
import InviteMember from 'containers/auth/invite-member';
import ForgotPassword from 'containers/auth/ForgotPassword';
import Dropbox from 'containers/auth/cloud-auth/dropbox';
import Google from 'containers/auth/cloud-auth/google';
import Onedrive from 'containers/auth/cloud-auth/onedrive';
import SignUpEmail from 'containers/auth/SignUpEmail';
import { SignInPhantom } from 'containers/auth/SignInPhantom';
import { SignUpMetamask } from 'containers/auth/SignUpMetamask';
import { SignInMetamask } from 'containers/auth/SignInMetamask';
import { NeyraRing } from 'containers/NeyraRing';
import CheckEmailChangeLinkActivate from 'containers/auth/CheckEmailChangeLinkActivate';
import JoinToWorkspace from 'containers/auth/JoinToWorkspace';
import OAuth from 'containers/oAuth';
import SharedByLinkFlow from 'containers/shared-by-link';
import SharedByLinkNftFlow from 'containers/share-by-link-nft';
import Editor from 'containers/editor/editor-component';
import SignInWithTelegram from 'containers/auth/SignInWithTelegram';
import { isElectron } from 'utils/isElectron';
import NeyraBios from 'containers/auth/NeyraBios';
import PublishAvatar from 'containers/auth/PublishAvatar';
import Terminal from 'containers/auth/Terminal';
import WelcomePage from 'containers/auth/WelcomePage';

import aiGenerator from 'containers/ai-generator';
import Mobile from 'containers/mobile';
import { isMobile } from 'utils/mobile';
import { useRedirect } from 'utils/hooks/use-redirect';
import usePageTitleAndMeta from 'utils/hooks/usePageTitleAndMeta';
import useTextToVoiceSpeaker from 'utils/hooks/useTextToVoiceSpeaker';
import useNewlyRegisteredUser from 'utils/hooks/useNewlyRegisteredUser';

import 'App.scss';

if (isElectron) {
  import('@metamask/sdk').then(({ default: MetaMaskSDK }) => {
    new MetaMaskSDK({
      useDeeplink: false,
      // @ts-ignore
      communicationLayerPreference: 'socket',
      forceInjectProvider: true,
    });
  }).catch((error) => {
    console.error('Failed to load MetaMaskSDK:', error);
  });
}

dotenv.config();

function getLibrary(provider: any) {
  return new Web3(provider);
}

const App = () => {
  usePageTitleAndMeta();
  const noDisplayApp = useRedirect();
  useTextToVoiceSpeaker({ playAudio: true });
  useNewlyRegisteredUser();

  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute(
      'src',
      'https://unpkg.com/node-forge@1.3.0/dist/forge.min.js'
    );
    document.body.appendChild(s);

    const languageDetection = JSON.parse(
      localStorage.getItem('language') as string
    );

    if (languageDetection === null) {
      const language = navigator.language.toLowerCase();
      if (language.includes('zh')) {
        i18n.changeLanguage('zh');
        locale('zh-cn');
      } else if (language.includes('fil')) {
        i18n.changeLanguage('fil');
        locale('fil');
      } else if (language.includes('id')) {
        i18n.changeLanguage('id');
        locale('id');
      } else {
        i18n.changeLanguage('en');
        locale('en');
      }
    } else {
      i18n.changeLanguage(languageDetection);
      locale(languageDetection);
    }
  }, []);

  if (noDisplayApp) {
    return null;
  }

  if (isMobile) {
    return <Mobile />;
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Switch>
        <Route exact path="/cloud-auth/dropbox/code" component={Dropbox} />
        <Route exact path="/cloud-auth/google/code" component={Google} />
        <Route exact path="/cloud-auth/onedrive/code" component={Onedrive} />
        <Route exact path="/ipfs" component={Ipfs} />
        <Route exact path="/sign-up-email" component={SignUpEmail} />
        <Route exact path="/sign-in-phantom" component={SignInPhantom} />
        <Route exact path="/" component={NeyraSignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/launch-neyra" component={NeyraRegistration} />
        <Route exact path="/registration" component={NeyraRegistration} />
        <Route exact path="/welcome" component={WelcomePage} />
        <Route exact path="/chat" component={Terminal} />
        <Route exact path="/bios" component={Terminal} />
        <Route exact path="/tg-analyze" component={Terminal} />
        <Route exact path="/drive" component={NeyraAI} />
        <Route exact path="/token" component={NeyraAI} />
        <Route exact path="/bios-settings" component={NeyraBios} />
        <Route exact path="/publish" component={PublishAvatar} />
        <Route exact path="/studio" component={aiGenerator} />
        <Route exact path="/sign-up-metamask" component={SignUpMetamask} />
        <Route exact path="/sign-in-metamask" component={SignInMetamask} />
        <Route exact path="/login-fleettec/:token" component={AuthToken} />
        <Route exact path="/file/:fileId" component={SharedByLinkFlow} />
        <Route exact path="/file/editor/:fileId" component={Editor} />
        <Route exact path="/oauth" component={OAuth} />
        <Route exact path="/ring" component={NeyraRing} />
        <Route exact path="/order-ring" component={OrderRing} />
        <Route
          exact
          path="/tokenized/:workspaceId/:slug/:contract_address/:networkId"
          component={SharedByLinkNftFlow}
        />
        <Route exact path="/w/:slug" component={JoinToWorkspace} />
        <Route exact path="/w/:slug/login" component={JoinToWorkspace} />
        <Route
          exact
          path="/change/email/:hash"
          component={CheckEmailChangeLinkActivate}
        />
        <Route exact path="/sign-in-email" component={SignInWithEmail} />
        <Route exact path="/sign-in-telegram" component={SignInWithTelegram} />
        <Route exact path="/no-workspace" component={NoWorkSpace} />
        <Route exact path="/create-workspace" component={CreateWorkspace} />
        <Route exact path="/invite-member" component={InviteMember} />
        <Route exact path="/join-now/:token" component={AcceptInvite} />
        <Route exact path="/join-member/:token" component={AcceptInvite} />
        <Route exact path="/join-guest/:token" component={AcceptInvite} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset/:token" component={NeyraSignIn} />
        <Route path="/get-invite" component={GetInvite} />
        <Route
          path="/**"
          render={(props: any) => <HomeResolver {...props} />}
        />
      </Switch>
    </Web3ReactProvider>
  );
};

export default withRouter(App);
