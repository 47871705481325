//Refactoring №3
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useAxios from 'axios-hooks';
import { useUpdateEffect } from 'ahooks';
import classNames from 'classnames';

import { store } from 'store/root-store';
import { setErrors } from 'store/errors/effects/set-error.effect';

import { selectProfileLogin } from 'features/profile/profileSlice';
import { selectVaultModal } from 'features/modals/modal-slice';
import { API_PUB } from 'constants/api-urls';
import { isShareMobileApp } from 'utils/url/is-page';
import getToken from 'utils/auth/get-token';

import NotificationPopup from './components/NotificationPopup';
import { ReactComponent as GDLogo } from 'containers/auth/SignIn/assets/GDLogo.svg';
import Button, { ButtonTheme } from 'components/Button';
import AccountSettingsButton from './components/AccountSettingsButton';
import AccountSettingsMenu from './components/AccountSettingsMenu';
import ClickOutside from 'components/shared/ClickOutside';
import BreadcrumbItem from '../breadcrumbs/breadcrumb-item';
import VaultModal from 'components/VaultModal';

// import DownloadIcon from 'components/svg/download-header';
// import PrintIcon from 'components/svg/print';

import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as PrintIcon } from './assets/print.svg';

import SmallRowIcon from 'components/svg/small-row';
import SquareIcon from 'components/svg/square';
import { ReactComponent as GhostLogoIcon } from './assets/ghost-logo.svg';
import { entitiesTypes } from 'config/entities-types';

import styles from './style.module.scss';

const NoAuthHeader = ({
  hideBreadcrumb = false,
  fileViewType,
  setFileViewTypeEffect,
  downloadEntities,
  printEntities,
  canBePrinted,
  files,
  isForbidden,
  getFolderName,
}) => {
  const isLogin = useSelector(selectProfileLogin);
  const isVaultOpen = useSelector(selectVaultModal);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const openProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };
  const closeProfileMenu = () => {
    setIsProfileMenuOpen(false);
  };
  const token = getToken();
  const history = useHistory();
  const { params } = useRouteMatch();

  const includesFolder = files.some((item) => item?.type === entitiesTypes.folder.id);

  const canBeDownLoaded =
    !isForbidden && files.length === 1 && !includesFolder && files.some((item) => item.is_downloaded);

  const [parent, setParent] = React.useState([]);
  const isGDLink = isShareMobileApp();

  const changeFileViewType = (type) => () => {
    setFileViewTypeEffect(type);
  };
  const pathname = window.location.pathname;
  const id = pathname.split('/')[2];

  const slug =
    !isForbidden && (params.folderId || params.fileId || files[0]?.slug || id);
  const [{ data: breadcrumbData, loading }] = useAxios(
    !isForbidden &&
      !params.contract_address && {
        url: `${API_PUB}/entry/${slug}`,
        method: 'GET',
        headers: {
          'X-Token': token,
        },
      }
  );

  var flattenObject = (tree) => {
    var result = [];
    var rec = (obj) => {
      if (obj?.parent) {
        rec(obj?.parent);
      }
      return result.push({ ...obj, parent: null });
    };
    rec(tree);
    return result;
  };

  useUpdateEffect(() => {
    setParent(flattenObject(breadcrumbData));
    getFolderName && getFolderName(breadcrumbData.name);
  }, [breadcrumbData]);

  const renderFileViewTypesSwitcher = () => {
    const smallListClasses = classNames({
      [styles.view__btn]: true,
      [styles.active]: fileViewType === 'small-row',
    });

    const blockClasses = classNames({
      [styles.view__btn]: true,
      [styles.active]: fileViewType === 'square',
    });

    return (
      <div className={styles.view__buttons}>
        <div
          className={smallListClasses}
          onClick={changeFileViewType('small-row')}
          data-test="no-auth-header_small-row_button"
        >
          <SmallRowIcon
            color={fileViewType === 'small-row' ? '#206ACF' : '#C4C4C4'}
          />
        </div>
        <div
          className={blockClasses}
          onClick={changeFileViewType('square')}
          data-test="no-auth-header_square_button"
        >
          <SquareIcon
            color={fileViewType === 'square' ? '#206ACF' : '#C4C4C4'}
          />
        </div>
      </div>
    );
  };

  const downloadEntity = () => {
    if (canBeDownLoaded) {
      downloadEntities();
    }
  };

  const printEntity = () => {
    if (canBePrinted) {
      printEntities();
    }
  };

  const goToLogin = () => {
    const fileSlug = params.folderId || params.fileId || files[0]?.slug || id;
    localStorage.setItem('FILE_SLUG', fileSlug);
    store.dispatch(setErrors({}));
    history.push('/');
  };
  const goToMain = () => {
    history.push('/main');
  };
  console.log('isGDLink ', isGDLink )
  
  return (
    <header className={styles.notAuthHeader}>
      <div className={styles.notAuthHeaderActions}>
        { isGDLink ? (
          <GDLogo
            className={styles.nonAuth__header__logoGg}
          />
          ) : (
            <GhostLogoIcon
              onClick={!isLogin ? goToLogin : goToMain}
              className={styles.nonAuth__header__logo}
            />
          )
        }
        <div className={styles.notAuthHeaderInfo}>
          {!isForbidden && (
            <div className={styles.nonAuth__header__buttons}>
              <button
                className={classNames({
                  [styles.nonAuth__header__btn]: true,
                  [styles.nonAuth__header__btn__dark]: true,
                  [styles.active]: canBeDownLoaded,
                  [styles.circleHover]: canBeDownLoaded,
                })}
                onClick={downloadEntity}
                data-test="no-auth-header_download_button"
                disabled={!canBeDownLoaded}
              >
                <DownloadIcon />
              </button>
              <button
                className={classNames({
                  [styles.nonAuth__header__btn]: true,
                  [styles.nonAuth__header__btn__light]: true,
                  [styles.active]: canBeDownLoaded,
                  [styles.circleHover]: canBeDownLoaded,
                })}
                onClick={printEntity}
                data-test="no-auth-header_print_button"
                disabled={!canBePrinted}
              >
                <PrintIcon />
              </button>
            </div>
          )}
          { !isGDLink && (
              !isLogin ? (
                <Button
                  theme={ButtonTheme.DARK}
                  className={styles.signInBtn}
                  onClick={goToLogin}
                  data-test="no-auth-header_go-to-login_button"
                >
                  Connect
                </Button>
              ) : (
                <div className={styles.nonAuth__header__settings}>
                  <NotificationPopup align="bottom" />
                  <ClickOutside onClickOutside={closeProfileMenu}>
                    <div
                      className={styles.nonAuth__header__settings__accountButton}
                    >
                      <AccountSettingsButton
                        onClick={openProfileMenu}
                        isOpenedMenu={isProfileMenuOpen}
                      />
                      {isProfileMenuOpen && (
                        <AccountSettingsMenu
                          hideThemeSwitch
                          onClose={closeProfileMenu}
                          className={styles.nonAuth__header__settings__menu}
                        />
                      )}
                    </div>
                  </ClickOutside>
                </div>
              )
            )
           }
        </div>
      </div>

      {!isForbidden && (
        <>
          <div className={styles.nonAuth__header__actions}>
            {!hideBreadcrumb && (
              <section className={styles.nonAuth__header__breadcrumb}>
                {!loading && (
                  <BreadcrumbItem
                    crumb={parent}
                    activeCrumbId={slug}
                    key={`breadcrumb-item-${slug}`}
                    pub
                    isGuest
                    data-test="no-auth-header_breadcrumb_button"
                  />
                )}
              </section>
            )}
            {renderFileViewTypesSwitcher()}
          </div>
        </>
      )}
      {isVaultOpen && <VaultModal files={files} isLogin={isLogin} />}
    </header>
  );
};

NoAuthHeader.defaultProps = {
  folderName: '',
  fileViewType: 'row',
  setFileViewTypeEffect: () => {},
  downloadEntities: () => {},
  printEntities: () => {},
  canBePrinted: false,
  files: [],
  onToggleFileInfo: () => {},
  infoIsOpen: false,
  isForbidden: false,
  showFolderPreview: false,
};

export default NoAuthHeader;
