// Refactoring №2
import React, { useState, useEffect } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { downloadFile } from 'gdgateway-client/lib/es5';
import 'react-h5-audio-player/lib/styles.css';

import { getDownloadOTT } from 'store/home/effects';
import getToken from 'utils/auth/get-token';

import AudioSVGImage from 'components/svg/audio-view';
import GhostLoader from 'components/GhostLoader';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';
import { actionsType } from 'config/actions-options';

const AudioStream = ({ file, fileContentRef }) => {
  const [ipfsAudio, setIpfsAudio] = useState();
  const [error, setError] = useState(null);
  const { t: accountT } = useTranslation('account');
  const { t: authT } = useTranslation('auth');

  const mainRequest = async (fileId) => {
    const {
      data: {
        user_tokens: { token: oneTimeToken },
        gateway,
      },
    } = await getDownloadOTT([{ slug: fileId }], actionsType.VIEWED);
    await sendFileViewStatistic(fileId);
    const url = `${gateway.url}/stream/${fileId}/${oneTimeToken}`;
    setIpfsAudio(url);
  };

  const pubRequest = async (fileId) => {
    const {
      data: {
        user_tokens: { token: oneTimeToken },
        gateway,
      },
    } = await getDownloadOTT([{ slug: fileId }], actionsType.VIEWED);

    await sendFileViewStatistic(fileId);
    const url = `${gateway.url}/stream/${fileId}/${oneTimeToken}`;
    setIpfsAudio(url);
  };

  const previewFromSP = async ({ file }) => {
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: file.slug }], actionsType.VIEWED);

    await sendFileViewStatistic(file.slug);
    const blob = await downloadFile({
      file,
      jwtOneTimeToken: jwt_ott,
      oneTimeToken,
      endpoint: gateway.url,
      isEncrypted: false,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[file.slug] || gateway.upload_chunk_size,
    });

    if (blob) {
      try {
        const realBlob = new Blob([blob]);
        const url = URL.createObjectURL(realBlob);
        setIpfsAudio(url);
      } catch (e) {
        setError(e);
        throw e;
      }
    }
  };

  useEffect(() => {
    const token = getToken();
    if (file.is_on_storage_provider) {
      previewFromSP({ file });
    } else {
      if (token) {
        mainRequest(file.slug, token);
      } else {
        pubRequest(file.slug);
      }
    }
  }, [file.slug]);

  const renderAudio = () => {
    if (error) {
      return <div className="error">{authT('common.internalServerError')}</div>;
    }
    if (!ipfsAudio) {
      return (
        <div className="loader">
          <GhostLoader
            texts={[
              accountT('convertModal.loading'),
              accountT('convertModal.pleaseWait'),
            ]}
          />
          ;
        </div>
      );
    }
    if (ipfsAudio) {
      return (
        <>
          <AudioSVGImage className="file-content-audio-image" />
          <AudioPlayer
            src={ipfsAudio}
            layout="horizontal-reverse"
            showJumpControls={false}
            showDownloadProgress
            customProgressBarSection={[
              RHAP_UI.CURRENT_TIME,
              <div key="elem" className="rhap_time">
                /
              </div>,
              RHAP_UI.DURATION,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.VOLUME,
            ]}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
            customIcons={{
              play: (
                <svg
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0L12 8L0 16V0Z" fill="#FFFFFF" />
                </svg>
              ),
              pause: <div>||</div>,
            }}
          />
        </>
      );
    }
  };
  return (
    <div className="file-view__audio-container">
      <div
        className="file-content-image-container file-content-image-container-default-column audio-player"
        ref={fileContentRef}
      >
        {renderAudio()}
      </div>
    </div>
  );
};

export default AudioStream;
