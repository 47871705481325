import axios from 'axios';
import UAuth from '@uauth/js';
import {
  API_AUTH,
  API_SIGN_UP_URL,
  API_USERS,
  API_SIGN_UP_METAMASK,
} from 'constants/api-urls';
import setToken from 'utils/auth/set-token';
import { loginUsernameEffect } from './login-username.effects';
import authRequest from 'utils/request/auth-request';

export const signUpMetamaskEffect = async (
  publicAddress,
  referral,
  network
) => {
  const body = {
    publicAddress,
    referral,
    tokenId: 1,
    network,
  };
  !referral && delete body.referral;
  return await axios
    .post(API_SIGN_UP_METAMASK, body)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data?.errors;
    });
};

export const signUpReferralMetamaskEffect = async (
  publicAddress,
  referral,
  is_coinbase
) => {
  const body = {
    publicAddress,
    referral,
    is_coinbase,
  };
  !referral && delete body.referral;
  return await axios
    .post(`${API_AUTH}/registration_metamask_free`, body)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
};

export const shortCryptoSignUp = async (token, email, name) => {
  let config = {
    params: {
      invite_code: token,
    },
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_PATH}/member/invite`,
      {
        displayedName: name,
        email,
      },
      config
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data?.errors;
    });
};

export const signUpEffect = async (
  username,
  password,
  code,
  workspace,
  preRegistrationToken,
  storage,
  price,
  history,
  referral
) => {
  let config = {};
  const priceParam = price ? { price } : {};
  if (preRegistrationToken) {
    config = {
      params: {
        invite_code: preRegistrationToken,
        ...priceParam,
      },
    };
  }

  const body = {
    password,
    email: username,
    code,
    workspace,
    storage,
    ...(referral && { referral }),
  };

  return await axios
    .post(API_SIGN_UP_URL, body, config)
    .then(() => {
      return loginUsernameEffect(username, password, history);
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data?.errors;
    });
};

export const signUpEmailConfirmationEffect = async (email) =>
  axios
    .post(`${API_AUTH}/confirmation`, { email })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data?.errors;
    });

export const signUpCheckEmailEffect = async (email) =>
  axios
    .post(`${API_AUTH}/check/email`, { email })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response?.data?.errors ||  error?.response?.data?.message;
    });

export const signUpCheckMetamaskEffect = async (publicAddress, token = null) =>
  axios
    .post(`${API_AUTH}/check/metamask`, {
      publicAddress,
      token,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response?.data;
    });

export const signUpCheckCodeEffect = async (email, code) =>
  axios
    .post(`${API_AUTH}/check/code`, { email, code })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response?.data?.errors;
    });

export const shortSignUpMetamaskEffect = async (publicAddress) =>
  axios
    .post(`${API_AUTH}/registration_metamask_free`, {
      publicAddress,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response?.data?.errors;
    });

export const signUpUnstoppableEfect = async (history) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const uauth = new UAuth({
      clientID: process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID,
      redirectUri: window.location.origin,
    });
    const authorization = await uauth.loginWithPopup();
    const { wallet_address, sub } = authorization.idToken || {};
    await axios.post(`${API_AUTH}/registration_unstoppable`, {
      public_address: wallet_address,
      domain: sub,
    });
    const { message, signature } = Object.values(
      authorization?.idToken?.proof
    )[0];
    const login = await axios.post(`${API_AUTH}/login_check_unstoppable`, {
      publicAddress: wallet_address,
      message,
      signature,
    });
    setToken(login);
    history.push('/chat');
  } catch (error) {
    throw error;
  }
};

export const connectWalletToAccountEffect = async (
  public_address,
  public_key,
  is_unstoppable,
  is_coinbase
) =>
  authRequest
    .post(`${API_USERS}/add/address`, {
      input: public_address,
      public_key: public_key,
      is_unstoppable: is_unstoppable,
      is_coinbase: is_coinbase,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response?.data?.errors;
    });
