/* eslint-disable for-direction */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import axios from 'axios';
import uuid from 'react-uuid';
import { downloadFile } from 'gdgateway-client/lib/es5';
import { CarReader } from '@ipld/car';

import authRequest from 'utils/request/auth-request-neyra';
import {
  setAiFiles,
  setProfile,
  setBios,
  startFetch,
  finishFetch,
  setAvatar,
  selectNeyraAvatar,
  setConversationHistory,
  createMessage,
  removeMessage,
} from 'features/neyra';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { NEIRA_AI_API, NEIRA_WHISPER_API } from 'constants/api-urls';
import { downloadFileData } from 'utils/file/library-callbacks';
import { addMessageToQueue } from 'features/speaker/speakerSlice';
import getToken, { getRefreshToken } from 'utils/auth/get-token';
import { LANG_KEY } from 'containers/account/Profile/components/NeyraLanguage';
import edjsHTML from 'utils/editorjs';

import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';

import { titles } from 'config/neyra-bios';
import { store } from 'store/root-store';
// import setToken from 'utils/auth/set-token';
import { setToken } from 'web3-auth/lib/es5';
import removeToken from 'utils/auth/remove-token';
import { isEmpty } from 'lodash';
import { getUserIp } from 'utils/getUserIp';

const STATUS_MESSAGE = 'success';

const succssChecker = ({ data }) => {
  if (data.status === STATUS_MESSAGE) {
    return data.data;
  }
  return new Error(data.message);
};

const commandChecker = (message) => {
  const commandRegex = /^\/.+$/;
  const isCommand = commandRegex.test(message);
  return isCommand;
};

export const getTempUser = async () => {
  const userIp = await getUserIp();
  const headers = {
    'Content-Type': 'application/json',
    'X-Real-IP': userIp,
  };
  return axios
    .get(`${NEIRA_AI_API}/auth/identity/temp_user`, {
      headers: headers,
    })
    .then(succssChecker);
};

export const createConversation = async (avatarId = 1) =>
  authRequest
    .put(
      `${NEIRA_AI_API}/conversation/avatar/create_conversation`,
      { avatar_id: avatarId, theme_id: 1 },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(succssChecker);

export const retrieveСonversationsStats = async () =>
  authRequest.get(
    `${NEIRA_AI_API}/public/avatar/retrieve_conversations_stats?avatar_id=${1}`
  );

export const sendVoiceMessageEffect = async (form) => {
  try {
    const res = await fetch(NEIRA_WHISPER_API, {
      method: 'POST',
      body: form,
    });

    return res.json();
  } catch (error) {
    console.warn(error);
  }
};

export const sendMessageClear = async ({
  accessToken,
  abortController,
  avatarId,
  uid,
  msgText,
  modifiers = {},
  setMessage,
}) => {
  const myAccessToken = accessToken || getToken();
  const controller = abortController || new AbortController();
  const signal = controller.signal;
  const userIp = await getUserIp();

  try {
    let res = await fetch(`${NEIRA_AI_API}/stream/avatar/chatv2`, {
      method: 'PUT',
      body: JSON.stringify({
        avatar_id: 1,
        theme_id: 1,
        uid: uid,
        request: msgText,
        modifiers: { focus: '', ...modifiers },
        language: 'en',
      }),
      headers: {
        Authorization: myAccessToken,
        'X-Real-IP': userIp,
      },
      signal,
    });

    if (res?.status === 401) {
      try {
        await getRefreshTokenEffect();
      } catch (error) {
        removeToken();
        window.location.href = '/connect';
      }
      res = await fetch(`${NEIRA_AI_API}/stream/avatar/chatv2`, {
        method: 'PUT',
        body: JSON.stringify({
          avatar_id: 1,
          theme_id: 1,
          uid: uid,
          request: msgText,
          modifiers: { focus: '', ...modifiers },
          language: 'en',
        }),
        headers: {
          Authorization: getToken(),
          'X-Real-IP': userIp,
        },
        signal,
      });
    }
    if (res?.status === 402 || res?.status === 'error') {
      return res;
    }

    const reader = res.body.getReader();
    let isFound = false;

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        return true;
      }
      var string = new TextDecoder().decode(value);
      let splt = string.split(`event:completion\ndata`);
      let finallyMsg = '';
      splt.forEach((item) => {
        if (item === '' || !item) {
          return;
        }
        if (!isFound) {
          finallyMsg += JSON.parse(item.replace(':', ''))?.message;
          if (Number(item.split(':')[1] !== 0)) {
            setMessage('');
            isFound = true;
          }
        } else {
          finallyMsg += JSON.parse(item.replace(':', ''))?.message;
        }
      });

      finallyMsg = RegExp(
        /[0-9a-zA-Z!@#$%^&*()—_+\-/`={};':"\\|,.<>? ]+/g
      ).test(finallyMsg)
        ? finallyMsg.replaceAll(/\n/g, '')
        : finallyMsg.replaceAll(/\n\n\n\n/g, '\n');

      setMessage(finallyMsg);
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      return true;
    } else {
      // console.error('Error occurred:', error);
      //TODO TMP START
      try {
        await getRefreshTokenEffect();
        return await sendMessage(
          getToken(),
          abortController,
          avatarId,
          uid,
          msgText,
          modifiers,
          setMessage
        );
      } catch (error) {
        removeToken();
        window.location.href = '/connect';
      }
      //TODO TMP END
    }
  }
};

export const sendMessage = async (
  accessToken,
  uid,
  msgText,
  setMessage,
  autoScroll = true,
  abortController = null,
  autoSpeaker = false,
  handleCommandFlow,
  avatarId = 1,
  modifiers = {}
) => {
  const neyraMsgId = uuid();
  const myAccessToken = accessToken || getToken();
  const controller = abortController || new AbortController();
  const lang = localStorage.getItem(LANG_KEY);
  const signal = controller.signal;
  const userIp = await getUserIp();

  try {
    setMessage
      ? setMessage((list) => {
          return [
            ...list,
            {
              id: neyraMsgId,
              text: '',
              voice: true,
              timeStamp: new Date(),
              isNeyro: true,
            },
          ];
        })
      : store.dispatch(
          createMessage({
            id: neyraMsgId,
            text: '',
            voice: true,
            timeStamp: new Date(),
            isNeyro: true,
          })
        );

    let res = await fetch(`${NEIRA_AI_API}/stream/avatar/chatv2`, {
      method: 'PUT',
      body: JSON.stringify({
        avatar_id: avatarId,
        theme_id: 1,
        uid: uid,
        request: msgText,
        modifiers: { focus: '', ...modifiers },
        language: lang || 'en',
      }),
      headers: {
        Authorization: myAccessToken,
        'X-Real-IP': userIp,
      },
      signal,
    });

    if (res?.status === 401) {
      try {
        await getRefreshTokenEffect();
      } catch (error) {
        removeToken();
        window.location.href = '/connect';
      }
      res = await fetch(`${NEIRA_AI_API}/stream/avatar/chatv2`, {
        method: 'PUT',
        body: JSON.stringify({
          avatar_id: 1,
          theme_id: 1,
          uid: uid,
          request: msgText,
          modifiers: { focus: '', ...modifiers },
          language: 'en',
        }),
        headers: {
          Authorization: getToken(),
          'X-Real-IP': userIp,
        },
        signal,
      });
    }

    if (res?.status === 402 || res?.status === 'error') {
      return res;
    }
    if (!res.ok) {
      return false;
    }

    const reader = res.body.getReader();
    let isFound = false;

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        return true;
      }
      var string = new TextDecoder().decode(value);
      let splt = string.split(`event:completion\ndata`);
      let finallyMsg = '';
      splt.forEach((item) => {
        if (item === '' || !item) {
          return;
        }
        if (!isFound) {
          finallyMsg += JSON.parse(item.replace(':', ''))?.message;
          if (Number(item.split(':')[1] !== 0)) {
            setMessage
              ? setMessage((list) =>
                  list.map((item) =>
                    item.id === neyraMsgId
                      ? {
                          ...item,
                          text: item.text + finallyMsg,
                          response: item.text + finallyMsg,
                        }
                      : item
                  )
                )
              : store.dispatch(
                  setConversationHistory({
                    id: neyraMsgId,
                    text: finallyMsg,
                    response: finallyMsg,
                  })
                );
            isFound = true;
          }
        } else {
          finallyMsg += JSON.parse(item.replace(':', ''))?.message;
        }
      });
      autoScroll && document?.getElementById(neyraMsgId)?.scrollIntoView();

      autoSpeaker && composeSentence(finallyMsg);

      const isMessaggeCommand = commandChecker(finallyMsg);

      if (isMessaggeCommand && handleCommandFlow) {
        const currentCommand = handleCommandFlow(finallyMsg);
        currentCommand && setMessage
          ? setMessage((list) =>
              list.map((item) =>
                item.id === neyraMsgId
                  ? {
                      ...item,
                      text: currentCommand.response,
                      response: currentCommand.response,
                    }
                  : item
              )
            )
          : store.dispatch(
              setConversationHistory({
                id: neyraMsgId,
                text: currentCommand.response,
                response: currentCommand.response,
              })
            );
      } else {
        setMessage
          ? setMessage((list) =>
              list.map((item) =>
                item.id === neyraMsgId
                  ? {
                      ...item,
                      text: item.text + finallyMsg,
                      response: item.text + finallyMsg,
                    }
                  : item
              )
            )
          : store.dispatch(
              setConversationHistory({
                id: neyraMsgId,
                text: finallyMsg,
                response: finallyMsg,
              })
            );
      }
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      return true;
    } else {
      // console.error('Error occurred:', error);
      //TODO TMP START
      try {
        await getRefreshTokenEffect();
        store.dispatch(removeMessage(neyraMsgId));
        return await sendMessage(
          getToken(),
          uid,
          msgText,
          setMessage,
          autoScroll,
          abortController,
          autoSpeaker,
          handleCommandFlow,
          avatarId,
          modifiers
        );
      } catch (error) {
        removeToken();
        window.location.href = '/connect';
      }
      //TODO TMP END
    }
  }
};

let unfinishedSentence = '';

const composeSentence = (finallyMsg) => {
  unfinishedSentence += finallyMsg;
  const punctuation = ['.', '?', '!'];

  for (let i = 0; i < unfinishedSentence.length; i++) {
    const char = unfinishedSentence[i];
    if (punctuation.includes(char)) {
      const sentencePart = unfinishedSentence.slice(0, i + 1);
      store.dispatch(addMessageToQueue(sentencePart));
      unfinishedSentence = unfinishedSentence.slice(i + 1);
      i = -1;
    }
  }
};

export const getCityList = async (input) => {
  try {
    const list = await authRequest.get(
      `${NEIRA_AI_API}/gateway/identity/check_location?q=${input}`
    );

    return list?.data || [];
  } catch (error) {
    console.warn(error);
  }
};

export const uploadAvatar = async (formData) => {
  try {
    const avatar = await authRequest.put(
      `${NEIRA_AI_API}/file/storage/upload_avatar`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return avatar;
  } catch (error) {
    console.warn(error);
  }
};

export const createAvatar = async (data, throwError = false) => {
  try {
    const avatar = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/create_avatarv2`,
      data
    );

    return avatar;
  } catch (error) {
    if (throwError) {
      throw error;
    } else {
      console.warn(error);
    }
  }
};

export const createBios = async (data) => {
  try {
    await authRequest.put(
      `${NEIRA_AI_API}/gateway/identity/create_humandesign`,
      data
    );
  } catch (error) {
    console.warn(error);
  }
};

export const getNeyraProfile = async () => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/identity/retrieve_user`
    );

    return data?.data?.data || {};
  } catch (error) {
    console.warn(error);
  }
};
export const saveNeyraSettings = async (setting) => {
  const data = await authRequest.put(
    `${NEIRA_AI_API}/gateway/identity/save_settings`,
    setting
  );

  return data;
};

export const getNeyraAvatar = async (user_id) => {
  try {
    store.dispatch(startFetch());
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_avatars`,
      { user_id },
      { params: { limit: 30, offset: 0 } }
    );
    if (data?.data?.data?.length) {
      store.dispatch(setAvatar(data?.data?.data));
    }
    store.dispatch(finishFetch());
    return data?.data?.data || {};
  } catch (error) {
    store.dispatch(finishFetch());
    console.warn(error);
  }
};

export const getNeyraBios = async () => {
  try {
    let tempArray = [];
    let allowedFields = [
      'mind.life_work',
      'body.subconscious_self_expresion',
      'mind.balance',
      'body.stability',
      'body.low_of_success',
      'mind.low_of_success',
      'body.world_view',
      'mind.world_view',
      'mind.deep_values',
    ];
    let talents = {
      'mind.life_work': '/work.svg',
      'body.subconscious_self_expresion': '/self.svg',
      'mind.balance': '/balance.svg',
      'body.stability': '/stabl.svg',
      'body.low_of_success': '/low.svg',
      'mind.low_of_success': '/brain.svg',
      'body.world_view': '/view.svg',
      'mind.world_view': '/view.svg',
      'mind.deep_values': '/hand.svg',
    };
    const response = await authRequest.get(
      `${NEIRA_AI_API}/gateway/identity/retrieve_talent`
    );
    const mas = [];
    if (response.status === 200) {
      Object.keys(response.data.data.spheres).forEach((item) => {
        if (allowedFields.includes(item)) {
          tempArray.push({
            ...response.data.data.spheres[item],
            icon: talents[item],
          });
        }
      });
      let initm = [
        {
          simple: response.data.data.spheres['mind.life_work'],
          reverse:
            response.data.data.spheres['body.subconscious_self_expresion'],
          icon: '/work.svg',
          icon_reverse: '/self_expresion.svg',
        },
        {
          simple: response.data.data.spheres['mind.balance'],
          reverse: response.data.data.spheres['body.stability'],
          icon: '/balance.svg',
          icon_reverse: '/self_improvement.svg',
        },
        {
          simple: response.data.data.spheres['mind.low_of_success'],
          reverse: response.data.data.spheres['body.low_of_success'],
          icon: '/brain.svg',
          icon_reverse: '/star_low.svg',
        },
        {
          simple: response.data.data.spheres['mind.world_view'],
          reverse: response.data.data.spheres['body.world_view'],
          icon: '/view.svg',
          icon_reverse: '/world_view.svg',
        },
        {
          simple: response.data.data.spheres['mind.life_lessons'],
          reverse: response.data.data.spheres['mind.life_lessons'],
          icon: '/self.svg',
        },
        {
          simple: response.data.data.spheres['mind.deep_values'],
          reverse: response.data.data.spheres['body.deep_values'],
          icon: '/hand.svg',
          icon_reverse: '/numbers.svg',
        },
        {
          simple: response.data.data.spheres['mind.communication'],
          reverse: response.data.data.spheres['body.communication'],
          icon: '/hub.svg',
          icon_reverse: '/forum.svg',
        },
        {
          simple: response.data.data.spheres['mind.chaos_to_order'],
          reverse: response.data.data.spheres['body.chaos_to_order'],
          icon: '/chaos.svg',
          icon_reverse: '/order.svg',
        },
        {
          simple: response.data.data.spheres['mind.emotional_power'],
          reverse: response.data.data.spheres['body.vocation'],
          icon: '/power.svg',
          icon_reverse: '/vocation.svg',
        },
      ];
      initm.forEach((item) => {
        mas.push(titles[item.simple['talent_index']]);
        mas.push(titles[item.reverse['talent_index']]);
      });

      const res = [
        {
          simple: response.data.data.spheres['mind.life_work'],
          reverse:
            response.data.data.spheres['body.subconscious_self_expresion'],
          icon: '/work.svg',
          icon_reverse: '/self_expresion.svg',
        },
        {
          simple: response.data.data.spheres['mind.balance'],
          reverse: response.data.data.spheres['body.stability'],
          icon: '/balance.svg',
          icon_reverse: '/self_improvement.svg',
        },
        {
          simple: response.data.data.spheres['mind.low_of_success'],
          reverse: response.data.data.spheres['body.low_of_success'],
          icon: '/brain.svg',
          icon_reverse: '/star_low.svg',
        },
        {
          simple: response.data.data.spheres['mind.world_view'],
          reverse: response.data.data.spheres['body.world_view'],
          icon: '/view.svg',
          icon_reverse: '/world_view.svg',
        },
        {
          simple: response.data.data.spheres['mind.life_lessons'],
          reverse: response.data.data.spheres['mind.life_lessons'],
          icon: '/self.svg',
        },
        {
          simple: response.data.data.spheres['mind.deep_values'],
          reverse: response.data.data.spheres['body.deep_values'],
          icon: '/hand.svg',
          icon_reverse: '/numbers.svg',
        },
        {
          simple: response.data.data.spheres['mind.communication'],
          reverse: response.data.data.spheres['body.communication'],
          icon: '/hub.svg',
          icon_reverse: '/forum.svg',
        },
        {
          simple: response.data.data.spheres['mind.chaos_to_order'],
          reverse: response.data.data.spheres['body.chaos_to_order'],
          icon: '/chaos.svg',
          icon_reverse: '/order.svg',
        },
        {
          simple: response.data.data.spheres['mind.emotional_power'],
          reverse: response.data.data.spheres['body.vocation'],
          icon: '/power.svg',
          icon_reverse: '/vocation.svg',
        },
      ];

      return { ...res.data?.data, spheres: res };
    }

    return {};
  } catch (error) {
    console.warn(error);
  }
};

export const getChatList = async (avatarId = 1) => {
  try {
    const { data } = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_conversations?avatar_id=${avatarId}&theme_id=1&external=0&limit=1000&offset=0`
    );
    return data?.data || [];
  } catch (error) {
    console.warn(error);
  }
};

export const getChatHistory = async (uid) => {
  try {
    const { data } = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_conversation?uid=${uid}&offset=0&limit=100`
    );
    return data?.data || [];
  } catch (error) {
    console.warn(error);
  }
};

export const getConversationsStats = () => async () => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/public/avatar/retrieve_conversations_stats`
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const getNeyraUserInfo = () => async (dispatch) => {
  try {
    const profile = await getNeyraProfile();
    if (profile.id) {
      await getNeyraAvatar(profile.id);
      const bios = await getNeyraBios();
      dispatch(setProfile(profile));
      dispatch(setBios(bios));
    }
  } catch (error) {
    console.warn(error);
  }
};

export const getRingInfo = async () => {
  try {
    await authRequest.get(`${NEIRA_AI_API}/gateway/identity/upmood_user_data`);
  } catch (error) {
    console.warn(error);
  }
};

export const getAvatarBySlug = async (slug) => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/public/avatar/public_retrieve_avatar?slug=${slug}`
    );
    return data?.data?.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAvatarById = async (id) => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_avatar?id=${id}`
    );
    return data?.data?.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getRefreshTokenEffect = async () => {
  try {
    const userIp = await getUserIp();

    const refresh_token = getRefreshToken();
    const data = await axios({
      method: 'post',
      url: `${NEIRA_AI_API}/auth/refresh_token`,
      headers: {
        'Content-Type': 'application/json',
        'X-Real-IP': userIp,
      },
      data: { refresh_token },
    });
    setToken(data?.data);
  } catch (error) {
    console.warn(error);
  }
};

export const createAccess = async (code) => {
  try {
    const data = await authRequest.post(
      `${NEIRA_AI_API}/gateway/identity/create_access`,
      { code }
    );

    return data?.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAccess = async () => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/identity/retrieve_access`
    );

    return data?.data?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const updateAvatar = async (id, domain) => {
  try {
    const data = await authRequest.patch(
      `${NEIRA_AI_API}/gateway/avatar/update_avatarv2`,
      { id, slug: domain }
    );

    return data?.data;
  } catch (error) {
    return new Error(error);
  }
};

export const updateAvatarEffect = async (reqData) => {
  try {
    const data = await authRequest.patch(
      `${NEIRA_AI_API}/gateway/avatar/update_avatarv2`,
      reqData
    );

    return data?.data;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export const uploadFileQueue = async (formData) => {
  try {
    const upload = await authRequest.put(
      `${NEIRA_AI_API}/file/storage/queue_file`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return upload?.data?.data?.path || '';
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const createQueue = async ({ url, uid }) => {
  try {
    const queue = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/create_queue`,
      { url, uid }
    );

    return queue?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const deleteQueue = async (uid) => {
  try {
    const queue = await authRequest.delete(
      `${NEIRA_AI_API}/gateway/avatar/delete_queue?uid=${uid}`
    );
    return queue;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const getQueueFiles = async (avatarId) => {
  const dispatch = store.dispatch;
  const params = { user_id: avatarId, limit: 1000 };

  try {
    const files = await authRequest.get(
      `${NEIRA_AI_API}/public/avatar/retrieve_queue`,
      {},
      { options: { params } }
    );

    dispatch(setAiFiles(files?.data?.data || []));
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const downloadFileToNeyraRead = async ({ dispatch, entity }) => {
  try {
    const controller = new AbortController();
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([entity.slug]);
    const { handlers } = downloadFileData;
    let cidData;

    const blob = await downloadFile({
      file: entity,
      oneTimeToken,
      jwtOneTimeToken: jwt_ott,
      endpoint: gateway.url,
      isEncrypted: false,
      callback: () => {},
      handlers,
      signal: controller.signal,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
      cidData,
    });
    const realBlob = new Blob([blob]);
    return realBlob;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const downloadMemoFileToNeyraRead = async ({ entity }) => {
  try {
    const data = await getPreviewObject({ file: entity, sendStatistic: false });

    const edjsHTMLParser = edjsHTML();
    const parsedMemoList = [
      `${data.name}\n`,
      ...edjsHTMLParser.parse(data),
    ].join();
    let blob = new Blob([parsedMemoList], { type: 'text/plain' });
    return blob;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const startNeyraRead = async ({ entity, dispatch, addNotification }) => {
  try {
    const avatar = selectNeyraAvatar(store.getState())[0];
    let blob;
    let file;
    if (entity.extension === 'memo') {
      blob = await downloadMemoFileToNeyraRead({ entity, dispatch });
      file = new File([blob], entity.name.replace('.memo', '.txt'));
    } else {
      blob = await downloadFileToNeyraRead({ entity, dispatch });
      file = new File([blob], entity.name);
    }

    const formData = new FormData();
    formData.append('file', file);
    let url;
    if (entity.mime.includes('image/') || entity.extension === 'webp') {
      formData.append('resource_id', 1);
      const data = await uploadAvatar(formData);
      url = data?.data?.data?.path;
    } else {
      url = await uploadFileQueue(formData);
    }

    const queue = await createQueue({ url, uid: entity.slug });

    if (queue.id) {
      getQueueFiles(avatar.id);
    }
  } catch (error) {
    addNotification(error.message, 'alert');
    throw new Error(error?.message || '');
  }
};

export const linkNeyraFetch = async (link) => {
  try {
    const response = await fetch(`https://r.jina.ai/${link}`, {
      method: 'GET',
    });
    return await response.text();
  } catch (error) {
    throw new Error(error?.message || '');
  }
};

export const deleteConversation = async ({ uid, avatarId }) => {
  const params = { user_id: avatarId, theme_id: 1 };

  try {
    const files = await authRequest.delete(
      `${NEIRA_AI_API}/gateway/avatar/delete_conversation?uid=${uid}`,
      { params }
    );

    return files;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const getAvatarApiKeys = async () => {
  try {
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_keys`
    );

    return data?.data?.data?.secrets;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const generateApiKeyEffect = async (body) => {
  try {
    const data = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/generate_key`,
      body
    );
    return data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const deleteApiKeyEffect = async (id) => {
  try {
    const data = await authRequest.delete(
      `${NEIRA_AI_API}/gateway/avatar/delete_key?id=${id}`
    );
    return data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const createKnowledge = async (body) => {
  try {
    const data = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/create_knowledge`,
      body
    );
    return data?.data?.data;
  } catch (error) {
    console.warn(error);
  }
};

export const createDataset = async (knowledgeName) => {
  try {
    const data = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/create_dataset`,
      { title: knowledgeName }
    );
    return data?.data?.data;
  } catch (error) {
    console.warn(error);
  }
};

export const getKnowledge = async ({ limit, offset, dataset_id }) => {
  try {
    const params = new URLSearchParams({ limit, offset, dataset_id });
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_knowledge?${params.toString()}`
    );

    return data?.data?.data || [];
  } catch (error) {
    console.warn(error);
  }
};

export const getDataset = async ({ limit, offset }) => {
  try {
    const params = new URLSearchParams({ limit, offset });
    const data = await authRequest.get(
      `${NEIRA_AI_API}/gateway/avatar/retrieve_dataset?${params.toString()}`
    );

    return data?.data?.data || [];
  } catch (error) {
    console.warn(error);
  }
};

export const getAllDataset = async () => {
  try {
    let datasetsArray = [];
    let memoDataset = [];
    let datasetsPage = 0;
    const pagination = {
      limit: 100,
      offset: 0,
    };

    for (let i = 1; i > 0; i++) {
      const datasetItem = await getDataset({
        ...pagination,
        offset: datasetsPage * pagination.limit,
      });
      // if (datasetItem.length < pagination.limit) {
      //   datasetsArray.push(...datasetItem)
      //   break
      // } else {
      //   datasetsArray.push(...datasetItem)
      //   datasetsPage = datasetsPage + 1;
      // }

      if (!datasetItem) {
        break;
      }

      const isMemoContain = datasetItem?.find((item) => item.title === 'memos');

      if (isMemoContain) {
        memoDataset.push(isMemoContain);
        break;
      } else {
        if (datasetItem.length < pagination.limit) {
          break;
        } else {
          datasetsPage = datasetsPage + 1;
        }
      }
    }

    if (isEmpty(memoDataset)) {
      return [];
    }

    for (let i = 0; i < memoDataset.length; i++) {
      const knowledgeArray = [];
      let knowledgePage = 0;
      const knowledgePagination = {
        limit: 100,
        offset: 0,
      };

      for (let x = 1; x > 0; x++) {
        const knowledgeItem = await getKnowledge({
          ...knowledgePagination,
          offset: knowledgePage * knowledgePagination.limit,
          dataset_id: datasetsArray[i].id,
        });

        if (knowledgeItem.length < knowledgePagination.limit) {
          knowledgeArray.push(...knowledgeItem);
          break;
        } else {
          knowledgeArray.push(...knowledgeItem);
          knowledgePage = knowledgePage + 1;
        }
      }

      memoDataset = memoDataset.map((item) => {
        if (item.id === memoDataset[i].id) {
          return {
            ...item,
            knowledges: knowledgeArray,
          };
        } else {
          return item;
        }
      });
    }

    return memoDataset;
  } catch (error) {
    console.warn(error);
  }
};

export const putDataKnowledge = async ({
  avatarId,
  title,
  message,
  knowledgeId,
}) => {
  try {
    const data = await authRequest.put(
      `${NEIRA_AI_API}/gateway/avatar/create_knowledge`,
      {
        dataset_id: knowledgeId,
        document: {
          title: title,
          body: message,
          tags: [],
        },
        avatar: [avatarId],
        paid: false,
      }
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};
